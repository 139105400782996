import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faLock, faUnlock, faPlus } from '@fortawesome/free-solid-svg-icons';
import AlertMessage from "../../Components/AlertMessage";
import api from "../../api/api";

export default function Compte() {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [confirmModal, setConfirmModal] = useState({ show: false, action: '', id: null });

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/admins');
                setItems(response.data);
            } catch (err) {
                console.error('Erreur lors de la récupération des utilisateurs :', err);
                setAlert({ type: 'error', message: 'Erreur lors de la récupération des utilisateurs.' });
            }
        }
        fetchUsers();
    }, []);

    const handleConfirm = (action, id) => {
        const user = items.find(item => item.id === id);
        if (user.username === 'root' && action === 'delete') {
            setAlert({ type: 'error', message: 'Le compte root ne peut pas être supprimé.' });
            return;
        }
        setConfirmModal({ show: true, action, id });
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`/delete/${id}`);
            setItems(items.filter(item => item.id !== id));
            setAlert({ type: 'success', message: 'Utilisateur supprimé avec succès.' });
        } catch (err) {
            console.error('Erreur lors de la suppression de l\'utilisateur :', err);
            setAlert({ type: 'error', message: 'Erreur lors de la suppression de l\'utilisateur.' });
        } finally {
            setConfirmModal({ show: false, action: '', id: null });
        }
    };

    const handleLockToggle = async (id) => {
        try {
            const response = await api.patch(`/islock/${id}/lock`);
            const updatedUser = response.data.user;
            setItems(items.map(item => item.id === id ? updatedUser : item));
            setAlert({
                type: 'success',
                message: `Utilisateur ${updatedUser.isLocked ? 'désactivé' : 'réactivé'} avec succès.`
            });
        } catch (err) {
            console.error('Erreur lors de la désactivation/réactivation de l\'utilisateur :', err);
            setAlert({
                type: 'error',
                message: 'Erreur lors de la désactivation/réactivation de l\'utilisateur.'
            });
        } finally {
            setConfirmModal({ show: false, action: '', id: null });
        }
    };

    const performAction = () => {
        if (confirmModal.action === 'delete') {
            handleDelete(confirmModal.id);
        } else if (confirmModal.action === 'toggleLock') {
            handleLockToggle(confirmModal.id);
        }
    };

    return (
        <div className="flex flex-col px-4 py-2 mx-4 md:mx-10">
            {alert.message && (
                <AlertMessage type={alert.type} message={alert.message} onClose={() => setAlert({})} />
            )}

            {/* Confirmation Modal */}
            {confirmModal.show && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="p-8 bg-white rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold text-gray-800">
                            {confirmModal.action === 'delete' ? 'Confirmer la suppression' : 'Confirmer le changement d\'état'}
                        </h2>
                        <p className="mt-4 text-gray-600">
                            {confirmModal.action === 'delete' ? 'Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.' : 
                            'Êtes-vous sûr de vouloir changer l\'état de cet utilisateur ?'}
                        </p>
                        <div className="flex justify-end mt-6 space-x-4">
                            <button
                                onClick={() => setConfirmModal({ show: false, action: '', id: null })}
                                className="px-4 py-2 font-medium text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={performAction}
                                className="px-4 py-2 font-medium text-white bg-red-600 rounded hover:bg-red-700"
                            >
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="overflow-x-auto">
                <table className="w-full my-5 overflow-x-auto bg-white rounded-lg shadow-lg">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Nom</th>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Prénoms</th>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Email</th>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Téléphone</th>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Privilège</th>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Date de création</th>
                            <th className="px-4 py-3 text-xs font-bold tracking-wider text-center text-gray-600 uppercase md:text-sm">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {items.map((data, idx) => (
                            <tr key={data.id} className="cursor-pointer hover:bg-gray-200" onClick={() => navigate(`/parametres/compte/${data.id}/activities`)}>
                                <td className="px-4 py-3 border-b border-gray-200 md:px-6">{data.lastName}</td>
                                <td className="px-4 py-3 truncate border-b border-gray-200 md:px-6">{data.firstName}</td>
                                <td className="px-4 py-3 truncate border-b border-gray-200 md:px-6">{data.email}</td>
                                <td className="px-4 py-3 border-b border-gray-200 md:px-6">{data.phone}</td>
                                <td className="px-4 py-3 border-b border-gray-200 md:px-6">{data.role}</td>
                                <td className="px-4 py-3 border-b border-gray-200 md:px-6">{new Date(data.createdAt).toLocaleDateString()}</td>
                                <td className="flex flex-col items-center justify-around gap-1 px-4 py-3 space-y-2 border-b border-gray-200 md:flex-row md:space-y-0 md:px-6">
                                    <button
                                        onClick={() => handleConfirm('delete', data.id)}
                                        className={`flex items-center ${data.username === 'root' ? 'text-gray-400 cursor-not-allowed' : 'text-red-600 hover:text-red-800'}`}
                                        disabled={data.username === 'root'}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Supprimer
                                    </button>
                                    <button
                                        onClick={() => handleConfirm('toggleLock', data.id)}
                                        className={`flex items-center text-${data.isLocked ? 'green' : 'yellow'}-600 hover:text-${data.isLocked ? 'green' : 'yellow'}-800`}
                                    >
                                        <FontAwesomeIcon icon={data.isLocked ? faUnlock : faLock} className="mr-2" />
                                        {data.isLocked ? 'Réactiver' : 'Désactiver'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-5 md:mt-4">
                <button
                    className="inline-block px-4 py-2 font-medium text-white duration-150 bg-indigo-600 rounded hover:bg-indigo-500 active:bg-indigo-700 md:text-sm"
                    onClick={() => navigate('/parametres/compte/addusers')}
                >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Ajouter
                </button>
            </div>
        </div>
    );
}
