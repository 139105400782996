import { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import Tab from "../../Components/Annonce/Tab";
import Appartement from "../../Components/Sections/Appartements";
import Reservation from "../../Components/Sections/Reservation";
import Paiement from "../../Components/Sections/Paiement";
import Annonce from "../Annonces/Annonce";
import PrOprietaire from "../../Components/Sections/Proprietaire";
import { MyContext } from "../../Components/Context";
import Client from "../../Components/Sections/Client";


export default function DetailsClient() {
    const { id } = useParams();
    const [clientDetails, setClientDetails] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Apercu");
    const tabItems = [
        "Apercu",
        "Reservations",
        "Paiements",


    ];

    const { actifItem, setActifItem } = useContext(MyContext);


    const apercuRef = useRef(null);
    const paiementRef = useRef(null);
    const reservationRef = useRef(null);

    useEffect(() => {
        const getClientDetails = async () => {
            try {
                const response = await api.get(`/clients/${id}`)
                setClientDetails(response.data)
            } catch (err) {
                console.error('Erreur lors de la récupération des détails du client !')
            }
        }

        getClientDetails();
    }, [id]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    switch (entry.target) {
                        case apercuRef.current:
                            setSelectedTab("Apercu");
                            break;

                        case paiementRef.current:
                            setSelectedTab("Paiements");
                            break;

                        case reservationRef.current:
                            setSelectedTab("Reservations");
                            break;

                        default:
                            break;
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (apercuRef.current) observer.observe(apercuRef.current);
        if (paiementRef.current) observer.observe(paiementRef.current);
        if (reservationRef.current) observer.observe(reservationRef.current);

        return () => {
            if (apercuRef.current) observer.unobserve(apercuRef.current);
            if (paiementRef.current) observer.unobserve(paiementRef.current);
            if (reservationRef.current) observer.unobserve(reservationRef.current);
        };
    }, []);


    const scrollToSection = (section) => {
        switch (section) {
            case "Apercu":
                apercuRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            case "Paiements":
                paiementRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            case "Reservations":
                reservationRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            default:
                break;
        }
    };

    return (

        <div className="grid grid-cols-12 gap-4">

            <div className="h-full col-span-12 row-auto p-4 overflow-auto md: lg:fixed top-12">
                <Tab tabItems={tabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onTabClick={scrollToSection} />
            </div>
            <div className="col-span-12 md:col-span-10 md:col-start-3" style={{ borderLeftWidth: 2 }}>

                {clientDetails &&

                    <div ref={apercuRef}>
                        <Client detail={clientDetails} />
                    </div>
                }


                {clientDetails &&
                    <div ref={reservationRef}>
                        <Reservation reservations={clientDetails.client.reservation} />
                    </div>

                }
                {clientDetails &&

                    <div ref={paiementRef}>
                        <Paiement paiements={clientDetails.paiements} />

                    </div>
                }

            </div>

        </div>
    )
}