import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { MyProvider } from './Components/Context';
import AppRoute from './Navigation/AppRoute';
import ExpireToken from './Components/ExpireToken';

function App() {
  return (
    <AuthProvider>
      <MyProvider>
        <Router>
        <ExpireToken/>
          <AppRoute />
        </Router>
      </MyProvider>
    </AuthProvider>
  );
}

export default App;
