import { Link, useNavigate } from "react-router-dom"
import Pagination from "../../Components/Pagination"
import { MyContext } from "../../Components/Context";
import { useContext } from "react";


export default function Plaintes(){


    const navigate = useNavigate();
    const {actifItem, setActifItem} = useContext(MyContext);
    
    

    const tableItems = [
        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Ouverte'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Expirée'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Expirée'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Expirée'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Expirée'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Cloturée'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Cloturée'
        },

        {
            id: "PLT-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            plaignant : 'Jean Mabiala',
            object : 'Reservation annulée et non remboursée',
            statut : 'Cloturée'
        },
        
    ]

    return (
        <div className="px-4 md:px-8">
            <div className="max-w-lg">
                <div>
                    <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                        Plaintes
                    </h3>
                    <p className="text-gray-600 mt-2">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                </div>

                
            </div>
            <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
                <table className="w-full table-auto text-sm text-left">
                    <thead className="bg-gray-50 text-gray-600 font-medium border-b">
                        <tr>
                            <th className="py-3 px-6">Id</th>
                            <th className="py-3 px-6">Plaignant</th>
                            <th className="py-3 px-6">Titre</th>
                            <th className="py-3 px-6">Date</th>
                            <th className="py-3 px-6">Statut</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                        {
                            tableItems.map((item, idx) => (
                                
                                    <tr key={idx} className="w-full text-left cursor-pointer hover:bg-gray-200 rounded transition duration-900"
                                        onClick={()=>{
                                            navigate(`/plainte/details/${idx}`)
                                            setActifItem('Plaintes')
                                        }}
                                    >
                                        
                                            <td className="flex items-center gap-x-3 py-3 px-6 whitespace-nowrap">
                                                <div>
                                                    <span className="block text-gray-700 text-sm font-medium">{item.id}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">{item.plaignant}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{item.object}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className=
                                                    {
                                                    `
                                                        ${item.statut === 'Ouverte' && 'bg-green-300 font-bold text-sm text-green-800 rounded-xl text-center px-4 py-1' } 
                                                        ${item.statut === 'Cloturée' && 'bg-purple-300 font-bold text-sm text-purple-800 rounded-xl text-center px-4 py-1'}
                                                        ${item.statut === 'Expirée' && 'bg-gray-300 font-bold text-sm text-gray-800 rounded-xl text-center px-4 py-1'}

                                                    `
                                                    
                                                    }>{item.statut}</span></td>
                                    </tr>
                                
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Pagination/>
        </div>
    )
}