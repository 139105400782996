import DragNDrop from "./DragnDrop"



export default function ProprioForm(){
    return (
        

            <form className="max-w-lg m-auto border-x-2 border-y-2 p-6 my-6 rounded-lg shadow-md bg-gray-50">
            <div className="my-6">
                <DragNDrop/>
            </div>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                    <label for="first_name" className="block text-gray-700 font-bold mb-2">Prenom</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="firsname" type="text" placeholder="Entrez le prénom"
                    />
                </div>
                <div>
                    <label for="last_name" className="block text-gray-700 font-bold mb-2">Nom</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastname" type="text" placeholder="Entrez le nom"
                    />
                </div>
                <div>
                    <label for="company" className="block text-gray-700 font-bold mb-2">Adresse</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="company" type="text" placeholder="Entrez l'adresse"
                    />
                </div>  
                <div>
                    <label for="phone" className="block text-gray-700 font-bold mb-2">Telephone</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="phone" type="tel" placeholder="Entrez le telephone"
                    />
                </div>
                <div>
                    <label for="website" className="block text-gray-700 font-bold mb-2">Entreprise</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="website" type="url" 
                    />
                </div>
                
                
            </div>
             
            <div className="mb-6">
                <label for="password" className="block text-gray-700 font-bold mb-2">Mot de passe</label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password" type="password" 
                />
            </div> 
            <div className="mb-6">
                <label for="confirm_password" className="block text-gray-700 font-bold mb-2">Confirmer le Mot de passe</label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="confirm_password" type="password" 
                />
            </div> 
            <div>
                <button type="submit" className=" block text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">Creer</button>
            </div>
            
            {/*<div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-purple-300  dark:border-gray-600 dark:focus:ring-purple-600 dark:ring-offset-gray-800" required />
                </div>
                <label for="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-purple-600 hover:underline dark:text-purple-500">terms and conditions</a>.</label>
    </div>*/}
        </form>

    )
}