import { useState, createContext } from "react";

const MyContext = createContext();

const MyProvider = ({children}) => {

    const [actifItem, setActifItem] = useState('Dashboard');
    const [dropdown, setDropDown] = useState(false)

    return (
        <MyContext.Provider value={{actifItem, setActifItem, dropdown, setDropDown}}>
            {children}
        </MyContext.Provider>
    );

};

export {MyContext, MyProvider};