import Loader from "../Loader";
import State from "../Stats/State";

export default function Client({ observed, detail }) {
    if (!detail) return <Loader/>;

    return (
        <div style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start' }} className="p-10">
            <div className="max-w-lg mb-16 ">
                <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">
                    Client
                </h3>
                {/* <p className="mt-2 text-gray-600">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p> */}
            </div>
            <div className="flex flex-row items-center gap-10">
                <div className={`grid-cols-4 md:grid-cols-2 ${observed === 'Proprietaire' && ' animate-fade-right animate-delay-700'}`}>
                    <img alt={`Profil de ${detail.client.firstname}`} src={detail.client.picture ? `${process.env.REACT_APP_API_PICTURE_URL}${detail.client.picture}` : "https://randomuser.me/api/portraits/men/86.jpg"} className="rounded-full w-52 h-52" />
                </div>


                <div className={`grip-cols-4 md:grid-cols-2 ${observed === 'Proprietaire' && ' animate-fade-left animate-delay-700'}`}>
                    <p className="text-xl font-bold text-gray-800 sm:text-2xl">{detail.client.firstname} {detail.client.lastname}</p>
                    {/* <p className="mt-2 text-gray-600">Expert d'etude en Informatique</p> */}
                </div>

            </div>
            <div className='grid items-start grid-cols-12 gap-10 mt-20 '>
                <div className={`pl-10 col-span-12 lg:col-span-6 ${observed === 'Proprietaire' && ' animate-fade-down animate-delay-200'}`}>
                    <div>
                        <span className="mr-5 text-xl font-medium text-gray-800">Telephone : </span>
                        <span className="text-gray-600">{detail.client.phone}</span>
                    </div>

                    <div>
                        <span className="mr-5 text-xl font-medium text-gray-800">Email : </span>
                        <span className="text-gray-600">{detail.client.email}</span>
                    </div>

                    <div>
                        <span className="mr-5 text-xl font-medium text-gray-800">Appreciation : </span>
                        <span className="px-3 py-1 font-bold text-green-800 bg-green-200 rounded-xl">Good</span>
                    </div>

                    {/*<div>
                        <span className="mr-5 text-xl font-medium text-gray-800">Addresse </span>
                        <span className="text-gray-600">14 Rue Matsiona, RDC </span>
                    </div>*/}

                    <div className="flex items-center justify-between flex-1 gap-4 mt-10">
                        <div className="w-full p-3 text-center text-white transition duration-150 bg-purple-800 rounded-lg cursor-pointer hover:bg-purple-600">
                            <p>Modifier</p>
                        </div>

                        <div className="w-full p-3 text-center text-white transition duration-150 bg-gray-800 rounded-lg cursor-pointer hover:bg-gray-600">
                            <span>Contacter</span>
                        </div>
                    </div>
                </div>

                <div className="col-span-12 lg:col-span-6">
                    <h3 className="mb-10 text-xl font-bold text-gray-800 sm:text-2xl">Rapports</h3>
                    <div className="grid grid-cols-3 lg:grid-cols-2 gap-9">


                        <div className={`grid-cols-1 ${observed === 'Proprietaire' && 'animate-fade-up animate-delay-200'}`}>
                            <State value={detail.paiements.count} name={'Paiements'} state={''} />
                        </div>

                        <div className={`grid-cols-1 ${observed === 'Proprietaire' && 'animate-fade-up animate-delay-200'}`}>
                            <State value={detail.reservationCount} name={'Reservation'} state={''} />
                        </div>
                    </div>

                </div>





            </div>
        </div>
    )
}