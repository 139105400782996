import { faBath} from '@fortawesome/free-solid-svg-icons/faBath'
import { faBed } from '@fortawesome/free-solid-svg-icons/faBed'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faBookmark } from '@fortawesome/free-solid-svg-icons/faBookmark'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck'
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faHotel } from '@fortawesome/free-solid-svg-icons/faHotel'
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse'
import { faHouseLock } from '@fortawesome/free-solid-svg-icons/faHouseLock'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faLocation } from '@fortawesome/free-solid-svg-icons/faLocation'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot'
import { faLocationPin } from '@fortawesome/free-solid-svg-icons/faLocationPin'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faStarHalf } from '@fortawesome/free-solid-svg-icons/faStarHalf'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import Dashboard from '../Screen/DashBoard/dashboard'
import { faBuildingUser, faBullhorn, faComment, faDashboard, faGear, faHandshakeAngle, faMessage, faPaperclip, faTicket } from '@fortawesome/free-solid-svg-icons'

const Icons = {
    bath :faBath,
    bed :faBed,
    bell :faBell,
    bookmark :faBookmark,
    calendar :faCalendar,
    calendarCheck :faCalendarCheck,
    car :faCar,
    caretLeft :faCaretLeft,
    check :faCheck,
    chevronLeft :faChevronLeft,
    envelope :faEnvelope,
    eye :faEye,
    eyeSlash :faEyeSlash,
    facebook :faFacebookF,
    google :faGoogle,
    heart :faHeart,
    hotel :faHotel,
    house :faHouse,
    houseLock :faHouseLock,
    home :faHome,
    location :faLocation,
    locationDot :faLocationDot,
    locationPin :faLocationPin,
    lock :faLock,
    lockOpen :faLockOpen,
    loupe :faMagnifyingGlass,
    phone :faPhone,
    sliders :faSliders,
    star :faStar,
    starHalf :faStarHalf,
    user :faUser,
    userCheck :faUserCheck,
    userCircle :faUserCircle,
    Dashboard : faDashboard,
    HandshakeAngle : faHandshakeAngle,
    Horn : faBullhorn,
    message : faComment,
    proprio : faBuildingUser,
    Gear : faGear,
    Ticket : faTicket,
    clip : faPaperclip
    
}


export default Icons;