import React, { useEffect, useState } from 'react';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import api from '../api/api';

const ExpireToken = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [manualClose, setManualClose] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            const expirationTime = decodedToken.exp * 1000;
            const currentTime = Date.now();
            const timeUntilExpiration = expirationTime - currentTime;

            if (timeUntilExpiration <= 0) {
                handleLogout();
                return;
            }

            setTimeLeft(timeUntilExpiration);

            if (timeUntilExpiration <= 5 * 60 * 1000 && !manualClose) {
                setShowAlert(true);
            } else {
                const alertTime = timeUntilExpiration - 5 * 60 * 1000;
                setTimeout(() => {
                    if (!manualClose) {
                        setShowAlert(true);
                    }
                }, alertTime);
            }

            const interval = setInterval(() => {
                const newTimeLeft = expirationTime - Date.now();
                if (newTimeLeft <= 0) {
                    clearInterval(interval);
                    handleLogout();
                } else {
                    setTimeLeft(newTimeLeft);
                    if (newTimeLeft <= 5 * 60 * 1000 && !manualClose && !showAlert) {
                        setShowAlert(true);
                    }
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [showAlert, manualClose]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    };

    const handleRefreshToken = async () => {
        try {
            const response = await api.post('/refresh-token');
            localStorage.setItem('token', response.data.token);
            const decodedToken = jwt_decode(response.data.token);
            const expirationTime = decodedToken.exp * 1000;
            setTimeLeft(expirationTime - Date.now());
            setShowAlert(false);
            setManualClose(false);
        } catch (error) {
            console.error('Erreur lors du rafraîchissement du token:', error);
            handleLogout();
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setManualClose(true);
    };

    if (!showAlert) return null;

    return (
        <div className={`fixed z-50 max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-lg bottom-4 right-4 dark:bg-gray-800 dark:border-gray-700 transition-transform duration-500 ease-in-out transform ${showAlert ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
            <div className="flex items-start justify-between">
                <div className="text-gray-900 dark:text-white">
                    <p className="mb-2 text-lg font-semibold">Expiration de la session</p>
                    <p>Votre session expirera dans {Math.ceil(timeLeft / 1000 / 60)} minutes. Voulez-vous rester connecté ?</p>
                </div>
                <button onClick={handleCloseAlert} className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="mt-4">
                <button
                    onClick={handleRefreshToken}
                    className="px-4 py-2 mr-2 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Rester connecté
                </button>
                <button
                    onClick={handleLogout}
                    className="px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                    Déconnexion
                </button>
            </div>
        </div>
    );
};

export default ExpireToken;
