
import './style.css'

export default function ColorAnimated({children}){
    return (
        <div
            className=" 
                bg-gradient-to-r
                from-purple-500
                via-indigo-500
                to-green-500
                background-animate
            "> 
            <div>
                {children}
            </div>
           
        </div>
    )
}
