import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from "../../api/api";
import Loader from '../../Components/Loader';
import AlertMessage from '../../Components/AlertMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function AdminActivities() {
    const { userId } = useParams();
    const [activities, setActivities] = useState([]);
    const [adminDetails, setAdminDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [showModal, setShowModal] = useState(false);

    // Pagination and Filtering State
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        status: '',
        searchTerm: '',
    });

    useEffect(() => {
        const fetchAdminDetails = async () => {
            try {
                const response = await api.get(`/detail-admin/${userId}`);
                setAdminDetails(response.data);
            } catch (err) {
                console.error('Erreur lors de la récupération des détails de l\'administrateur :', err);
                setAlert({ type: 'error', message: 'Erreur lors de la récupération des détails de l\'administrateur.' });
            }
        };

        const fetchActivities = async () => {
            try {
                const response = await api.get(`/${userId}/activities`, {
                    params: {
                        page,
                        pageSize,
                        ...filters,
                    }
                });
                setActivities(response.data.activities);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                console.error('Erreur lors de la récupération des activités :', err);
                setAlert({ type: 'error', message: 'Erreur lors de la récupération des activités.' });
            } finally {
                setLoading(false);
            }
        };

        fetchAdminDetails();
        fetchActivities();
    }, [userId, page, pageSize, filters]);

    const handleRegeneratePassword = async () => {
        setShowModal(false);
        try {
            await api.patch(`/regenerate-password/${userId}`);
            setAlert({ type: 'success', message: 'Le mot de passe a été régénéré avec succès.' });
        } catch (err) {
            console.error('Erreur lors de la régénération du mot de passe :', err);
            setAlert({ type: 'error', message: 'Erreur lors de la régénération du mot de passe.' });
        }
    };

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleSearch = () => {
        setPage(1); // Reset to first page when applying filters
        setLoading(true);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <div className="flex flex-col px-4 py-6 mx-4 md:mx-10">
            {loading ? (
                <Loader />
            ) : (
                <>
                    {adminDetails && (
                        <div className="p-6 mb-6 text-white rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-3xl font-bold">{adminDetails.firstName} {adminDetails.lastName}</h2>
                                    <p className="mt-2">Email : {adminDetails.email}</p>
                                    <p className="mt-1 text-sm opacity-75">Rôle : {adminDetails.role}</p>
                                </div>
                                <button
                                    onClick={() => setShowModal(true)}
                                    className="flex items-center px-4 py-2 mt-4 font-semibold text-indigo-600 bg-white rounded-lg shadow hover:bg-gray-100"
                                >
                                    <FontAwesomeIcon icon={faKey} className="mr-2" />
                                    Régénérer le mot de passe
                                </button>
                            </div>
                        </div>
                    )}
                    {alert.message && (
                        <AlertMessage type={alert.type} message={alert.message} onClose={() => setAlert({})} />
                    )}

                    {/* Filter and Search Section */}
                    <div className="p-4 mb-4 bg-white rounded-lg shadow-lg">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Date de début</label>
                                <input
                                    type="date"
                                    name="startDate"
                                    value={filters.startDate}
                                    onChange={handleFilterChange}
                                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Date de fin</label>
                                <input
                                    type="date"
                                    name="endDate"
                                    value={filters.endDate}
                                    onChange={handleFilterChange}
                                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Statut</label>
                                <select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <option value="">Tous les statuts</option>
                                    <option value="success">Succès</option>
                                    <option value="failed">Échec</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Rechercher</label>
                                <input
                                    type="text"
                                    name="searchTerm"
                                    value={filters.searchTerm}
                                    onChange={handleFilterChange}
                                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Rechercher par action"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-4 space-x-4">
                            <button
                                onClick={handleSearch}
                                className="px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg shadow hover:bg-indigo-500"
                            >
                                Rechercher
                            </button>
                            <button
                                onClick={() => setFilters({
                                    startDate: '',
                                    endDate: '',
                                    status: '',
                                    searchTerm: '',
                                })}
                                className="px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded-lg shadow hover:bg-gray-300"
                            >
                                Réinitialiser
                            </button>
                        </div>
                    </div>

                    <div className="p-6 bg-white rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold text-gray-800">Activités récentes</h3>
                        {activities.length === 0 ? (
                            <div className="flex justify-center py-10">
                                <p className="text-lg font-semibold text-gray-500">Aucune activité pour le moment.</p>
                            </div>
                        ) : (
                            <ul className="mt-4 space-y-4">
                                {activities.map((activity, index) => (
                                    <li key={index} className="flex items-start p-4 rounded-lg shadow-sm bg-gray-50">
                                        <div className="flex-shrink-0">
                                            <FontAwesomeIcon
                                                icon={activity.success ? faCheckCircle : faTimesCircle}
                                                className={`text-2xl ${activity.success ? 'text-green-500' : 'text-red-500'}`}
                                            />
                                        </div>
                                        <div className="ml-4">
                                            <p className="text-gray-700"><strong>Action :</strong> {activity.action}</p>
                                            <p className="text-gray-500"><strong>Détails :</strong> {activity.details}</p>
                                            <p className="mt-1 text-sm text-gray-400">{new Date(activity.createdAt).toLocaleString()}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {/* Pagination Section */}
                        <div className="flex items-center justify-between mt-6">
                            <button
                                onClick={() => handlePageChange(page - 1)}
                                disabled={page <= 1}
                                className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
                            >
                                Précédent
                            </button>
                            <span>Page {page} sur {totalPages}</span>
                            <button
                                onClick={() => handlePageChange(page + 1)}
                                disabled={page >= totalPages}
                                className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
                            >
                                Suivant
                            </button>
                        </div>
                    </div>

                    {showModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                            <div className="p-8 bg-white rounded-lg shadow-lg">
                                <h2 className="text-lg font-semibold text-gray-800">Confirmer la régénération du mot de passe</h2>
                                <p className="mt-4 text-gray-600">Êtes-vous sûr de vouloir régénérer le mot de passe de cet utilisateur ? Cette action ne peut pas être annulée.</p>
                                <div className="flex justify-end mt-6 space-x-4">
                                    <button
                                        onClick={() => setShowModal(false)}
                                        className="px-4 py-2 font-medium text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        onClick={handleRegeneratePassword}
                                        className="px-4 py-2 font-medium text-white bg-red-600 rounded hover:bg-red-700"
                                    >
                                        Confirmer
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
