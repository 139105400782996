const State = ({value, state, name})=>{
    return (

        <>
        {state === 'res' &&    <div className="relative p-6 rounded-2xl bg-green-600 shadow">
                <div class="space-y-2">
                    <div
                        class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-100 dark:text-gray-100">
                        <span>{name}</span>
                    </div>

                    <div class="text-3xl dark:text-gray-100">
                        {value}
                    </div>
                </div>
            </div>}

            {state === 'cours' &&    <div className="relative p-6 rounded-2xl bg-blue-600 shadow">
                <div class="space-y-2">
                    <div
                        class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-100 dark:text-gray-100">
                        <span>{name}</span>
                    </div>

                    <div class="text-3xl dark:text-gray-100">
                        {value}
                    </div>
                </div>
            </div>}


            {state === 'echec' &&    <div className="relative p-6 rounded-2xl bg-red-600 shadow">
                <div class="space-y-2">
                    <div
                        class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-100 dark:text-gray-100">
                        <span>{name}</span>
                    </div>

                    <div class="text-3xl dark:text-gray-100">
                        {value}
                    </div>
                </div>
            </div>}

            {state === '' &&    <div className="relative p-6 rounded-2xl dark:bg-gray-800 shadow">
                <div class="space-y-2">
                    <div
                        class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-100 dark:text-gray-100">
                        <span>{name}</span>
                    </div>

                    <div class="text-3xl dark:text-gray-100">
                        {value}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default State;