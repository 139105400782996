import React, { useState } from "react";
import api from "../../api/api";
import AlertMessage from "../../Components/AlertMessage";

export default function AddUsers() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        role: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');
        try {
            const response = await api.post('/create', formData);
            setSuccess(`Utilisateur créé avec succès. Mot de passe: ${response.data.generatedPassword}`);
            setFormData({
                firstName: '',
                lastName: '',
                username: '',
                email: '',
                phone: '',
                role: '',
            });
        } catch (err) {
            console.error('Erreur lors de la création de l\'utilisateur:', err);
            setError('Erreur lors de la création de l\'utilisateur');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 overflow-hidden bg-white rounded-lg shadow-lg">
            <div className="px-6 py-4 text-2xl font-bold text-center text-white uppercase bg-purple-700">
                Création utilisateur
            </div>
            <form className="px-6 py-4" onSubmit={handleSubmit}>
                

                <div className="mb-4">
                    <label className="block mb-2 font-bold text-gray-700" htmlFor="firstName">
                        Prénoms
                    </label>
                    <input
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="firstName"
                        type="text"
                        placeholder="Entrez le prénom"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                
                <div className="mb-4">
                    <label className="block mb-2 font-bold text-gray-700" htmlFor="lastName">
                        Nom
                    </label>
                    <input
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="lastName"
                        type="text"
                        placeholder="Entrez le nom"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                
                <div className="mb-4">
                    <label className="block mb-2 font-bold text-gray-700" htmlFor="username">
                        Nom d'utilisateur
                    </label>
                    <input
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        placeholder="Entrez le nom d'utilisateur"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-bold text-gray-700" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        placeholder="Entrez l'email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                
                <div className="mb-4">
                    <label className="block mb-2 font-bold text-gray-700" htmlFor="phone">
                        Téléphone
                    </label>
                    <input
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="phone"
                        type="tel"
                        placeholder="Entrez le téléphone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 font-bold text-gray-700" htmlFor="role">
                        Privilège
                    </label>
                    <select
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="role"
                        value={formData.role}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Sélectionnez un privilège</option>
                        <option value="superadmin">Superadmin</option>
                        <option value="admin">Admin</option>
                        <option value="supervisor">Superviseur</option>
                    </select>
                </div>

                <div className="flex items-center justify-center mb-4">
                    <button
                        className="w-full px-4 py-2 text-white bg-purple-700 rounded hover:bg-purple-900 focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Création en cours...' : 'Créer'}
                    </button>
                </div>
                <AlertMessage message={error} type="error" onClose={() => setError('')} />
                <AlertMessage message={success} type="success" onClose={() => setSuccess('')} />
            </form>
        </div>
    );
}
