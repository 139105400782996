import api from "./api";

export const login = async (email, password) => {
    try{
        const response = await api.post('/login',{email,password});
        return response.data;
    } catch (err) {
        console.error(err)
        if (err.response && err.response.data) {
            throw new Error(err.response.data); 
        } else {
            throw new Error('Failed to login');
        }
    }
    
}

