import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Screen/DashBoard/dashboard";
import Dash from "../Screen/DashBoard/dash";
import Annonce from "../Screen/Annonces/Annonce";
import DetailsAnnonce from "../Screen/Annonces/Details";
import ListeClients from "../Screen/Clients/listeClients";
import Parametres from "../Screen/Parametres/parametres";
import Proprietaires from "../Screen/Proprietaires/proprietaires";
import ProfilMenu from "../Screen/Profil/profilMenu";
import Reservation from "../Screen/Rerservations/Reservation";
import DetailsReservation from "../Screen/Rerservations/DetailsReservation";
import DetailProfil from "../Screen/Profil/detailProfil";
import Compte from "../Screen/Parametres/Compte";
import Facturation from "../Screen/Parametres/facturations";
import Integration from "../Screen/Parametres/integration";
import DetailCompte from "../Screen/Parametres/détailcompte";

import Connexion from '../Screen/Connexion/connexion';
import Appartement from "../Components/Sections/Appartements";
import DetailsProprio from "../Screen/Proprietaires/Details";
import DetailIntegration from "../Screen/Parametres/detailIntegration";
import ClientForm from '../Components/Forms/Client';
import ProprioForm from '../Components/Forms/Proprietaire';
import AddUsers from "../Screen/Parametres/addUsers";
import DetailsClient from "../Screen/Clients/detailClients";
import ListAppart from "../Components/Sections/ListAppart";
import Plaintes from "../Screen/Plaintes/plaintes";
import DetailsPlainte from "../Screen/Plaintes/detailsPlainte";
import PlainteReservation from "../Screen/Plaintes/PlainteReservation";
import DetailPaie from "../Screen/Plaintes/detailPaie";
import PrivateRoute from "./PrivateRoute";
import RoleBasedRoute from "./RoleBasedRoute";
import PreviewGalerie from "../Components/Galerie/PreviewGalerie";
import Annonces from "../Screen/Annonces/Annonces";
import AdminActivities from "../Screen/Parametres/adminActivities";
import PushNotifications from "../Screen/Parametres/notificationsPush";

const AppRoute = () => {
  return (
      <Routes>
        <Route path="/login" element={<Connexion />} />
        <Route path="/restor" element={<p>Restaurer votre mot de passe</p>} />

        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
          <Route index element={<Dash />} />
          <Route path="annonce" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Annonces /></RoleBasedRoute>} />
          <Route path="plaintes" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Plaintes /></RoleBasedRoute>} />
          <Route path="plainte/details/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsPlainte /></RoleBasedRoute>}>
            <Route path="reservation" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><PlainteReservation /></RoleBasedRoute>} />
            <Route path="annonce" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsAnnonce /></RoleBasedRoute>} />
            <Route path="paiements" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailPaie /></RoleBasedRoute>} />
            <Route path="proprietaire" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsProprio /></RoleBasedRoute>} />
            <Route path="client" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsClient /></RoleBasedRoute>} />
          </Route>
          <Route path="appartement/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Appartement /></RoleBasedRoute>} />
          <Route path="appartement-proprio/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><ListAppart /></RoleBasedRoute>} />
          <Route path="proprietaires/details/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsProprio /></RoleBasedRoute>} />
          <Route path="client" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><ListeClients /></RoleBasedRoute>} />
          <Route path="client/details/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsClient /></RoleBasedRoute>} />
          <Route path="parametres" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Parametres /></RoleBasedRoute>}>
            <Route path="compte" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Compte /></RoleBasedRoute>} />
            <Route path="facturation" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Facturation /></RoleBasedRoute>} />
            <Route path="integration" element={<RoleBasedRoute allowedRoles={['superadmin']}><Integration /></RoleBasedRoute>} />
            <Route path="integration/detailintegration" element={<RoleBasedRoute allowedRoles={['superadmin']}><DetailIntegration /></RoleBasedRoute>} />
            <Route path="compte/:id/details" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailProfil /></RoleBasedRoute>} />
            <Route path="compte/addusers" element={<RoleBasedRoute allowedRoles={['superadmin']}><AddUsers /></RoleBasedRoute>} />
            <Route path="compte/:userId/activities" element={<RoleBasedRoute allowedRoles={['superadmin']}><AdminActivities /></RoleBasedRoute>} />
            <Route path="push" element={<RoleBasedRoute allowedRoles={['superadmin']}><PushNotifications /></RoleBasedRoute>} />
          </Route>
          <Route path="reservation" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Reservation /></RoleBasedRoute>} />
          <Route path="reservation/details/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsReservation /></RoleBasedRoute>} />
          <Route path="annonce/details/:id" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailsAnnonce /></RoleBasedRoute>} />
          <Route path="proprietaires" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><Proprietaires /></RoleBasedRoute>} />
          <Route path="profilmenu" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><ProfilMenu /></RoleBasedRoute>} />
          <Route path="client/creation" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><ClientForm /></RoleBasedRoute>} />
          <Route path="proprietaire/creation" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><ProprioForm /></RoleBasedRoute>} />
          <Route path="preview" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><PreviewGalerie /></RoleBasedRoute>} />
          <Route path="/detailprofil" element={<RoleBasedRoute allowedRoles={['admin', 'superadmin', 'supervisor']}><DetailProfil /></RoleBasedRoute>} />
        </Route>
      </Routes>
  );
};

export default AppRoute;
