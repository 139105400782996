import { useState, useRef, useEffect } from "react"
import CommentDetails from "../Modal/CommentDetails"


export default function Comments(commentaire){

    const [show, setShow] = useState(false)

    
    return (

        <>
            <div
                href="#" 
                type="button" 
                className="cursor-pointer block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700" 
                onClick={()=>setShow(true)}
            >

            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{commentaire.client.firstname} {commentaire.client.lastname}</h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">{commentaire.comment}</p>
            </div>

            {show && <CommentDetails open={show} setOpen={setShow} item={{'client' : `${commentaire.client.firstname} ${commentaire.client.lastname}`, 'date' : `${new Date(commentaire.createdAt).toLocaleDateString()}`, 'comment' : `${commentaire.comment}`}}/>}

        </>
    )
}