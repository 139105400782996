import { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icons from '../../utils/icons';
import Blob from '../../Components/Blob/Blob';
import { Oval } from 'react-loader-spinner';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function Connexion() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const from = location.state?.from?.pathname || "/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      await login(email, password);
      navigate(from, { replace: true });
    } catch (err) {
      setError(err.message);
      console.error('Erreur de connexion', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 5000); // Erreur disparaît après 5 secondes

      return () => clearTimeout(timer); // Nettoyer le timer si le composant est démonté ou l'erreur change
    }
  }, [error]);

  return (
    <>
      <Blob />
      <div className="flex justify-center min-h-screen text-gray-900 bg-gray-100">
        <div className="z-20 flex justify-center flex-1 max-w-screen-xl m-0 overflow-hidden bg-white shadow sm:m-10 sm:rounded-lg">
          <div className="flex items-center justify-center p-6 lg:w-1/2 xl:w-5/12 sm:p-12">
            <div className="flex flex-col items-center">
              <h1 className="text-2xl font-extrabold xl:text-3xl">Connexion</h1>
              <div className="items-center justify-center flex-1 w-full mt-5">
                {error && (
                  <p className="p-3 mt-4 text-center text-red-700 bg-red-100 border border-red-400 rounded-md animate-bounce-in">
                    {error}
                  </p>
                )}
                <form onSubmit={handleSubmit} className="items-center justify-center flex-1 max-w-xs m-auto">
                  <input
                    className="w-full px-8 py-4 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className='relative'>

                  <input
                    className="z-10 w-full px-8 py-4 mt-5 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                    type={show ? "text" : "password"}
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button onClick={() => setShow(!show)} type='button' className='absolute z-20 top-1/4 right-5 hover:text-gray-400' >

                    <FontAwesomeIcon 
                      icon={show ? faEyeSlash : faEye} 
                      size='20'
                    />
                  </button>
                  </div>
                  <button
                    type="submit"
                    className="flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out bg-indigo-500 rounded-lg hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#ffffff"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#cccccc"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={Icons.user} /> <span className="ml-3">Connexion</span>
                      </>
                    )}
                  </button>
                  <p className="mt-6 text-xs text-center text-gray-600">
                    J'accepte de respecter les <a href="#" className="border-b border-gray-500 border-dotted"> conditions d'utilisation </a> et la <a href="#" className="border-b border-gray-500 border-dotted">politique de confidentialité</a> de Kln.
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="flex-1 hidden overflow-hidden text-center bg-indigo-100 lg:flex">
            <div
              className="w-full m-12 bg-center bg-no-repeat bg-contain xl:m-16"
              style={{
                backgroundImage: 'url(https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg)',
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
