import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icons from "../../utils/icons"
import { useNavigate } from "react-router-dom"

export default function Appartcard({id}) {

    const navigate = useNavigate();

    return(
        <div className="max-w-md w-80 mx-auto rounded-md overflow-hidden shadow-md hover:shadow-lg">
            <div className="relative">
                <img className="w-full" src="https://www.laconstructionlyonnaise.fr/wp-content/uploads/2022/11/belle-maison-archi-LCL.jpg" alt="Appart Image"/>
                <div className="absolute top-0 right-0 bg-gray-800 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">Meublé
                </div>
            </div>
            <div className="p-4">
                <div className=" flex flex-row justify-between items-center mb-2">
                    <h3 className="text-lg font-bold">STUDIO</h3>
                    <span className="bg-green-100 text-green-800 font-bold pr-3 pl-3 text-center rounded-xl">Libre</span>
                </div>
                

                <p className="text-gray-600 text-sm mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae ante
                    vel eros fermentum faucibus sit amet euismod lorem.</p>

                <div className="flex justify-between items-center m-5">
                    <span > 
                        <FontAwesomeIcon icon={Icons.bed} classNameName=" text-purple-800 mr-2"/>
                        <p className=" text-gray-600">4 lits</p>
                    </span>

                    <span>
                        <FontAwesomeIcon icon={Icons.bath} className=" text-purple-800 mr-2"/>
                        <p className=" text-gray-600">2 salles de bains</p>
                    </span>

                    <span>
                        <FontAwesomeIcon icon={Icons.car} className=" text-purple-800 mr-2"/>
                        <p className=" text-gray-600">Parking</p>
                    </span>
                </div>

                <div className="flex items-center justify-between">
                    <span className="font-bold text-lg">250 000 XAF / jour</span>
                    <button className="bg-purple-800 hover:bg-purle-600 text-white font-bold py-2 px-4 rounded" onClick={()=>{navigate(`/appartement/${id}`)}}>
                Voir
            </button>
                </div>
            </div>
        </div>
    )
}