import React from "react"

export default function DetailIntegration(){
   
    return(
<div className="max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
  
    <form className="py-4 px-6" action="" method="POST">
        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" for="name">
                Nom
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name" type="text" />
        </div>
        
        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" for="email">
                Email
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email" type="email" />
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" for="apikey">
                APIkey
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="apikey" type="apikey" />
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" for="services">
                Services
            </label>
            <select
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="privilege" name="privilege">
                <option value="">Entrez le services</option>
                <option value="haircut">services</option>
                
            </select>
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" for="date">
               Date subscription
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="date" type="date"/>
        </div>

        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" for="date">
               Date expiration
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="date" type="date"/>
        </div>
       
       
        <div className="flex items-center justify-center mb-4">
            <button
                className="w-full bg-purple-700 text-white py-2 px-4 rounded hover:bg-purple-900 focus:outline-none focus:shadow-outline"
                type="submit">
               Subscrire
            </button>
        </div>

    </form>
</div>
    )
}