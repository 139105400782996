import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Galerie from "../Galerie/Galerie";
import Icons from "../../utils/icons";

export default function Appartement({ detail }) {
    return (
        <>
            <p className="p-5 mt-5 text-2xl font-bold dark:text-gray-800 animate-fade-right">Details du bien</p>
            <div className="grid items-start grid-cols-12 gap-10 animate-fade-left">

                <div className="col-span-12 p-4 md:col-span-6">
                    <img alt="" src={`${process.env.REACT_APP_API_PICTURE_URL}${detail.picture}`} className="flex w-full rounded flex-start" />

                    {/*<div className="mt-10">
                        <p className="mb-5 text-xl font-bold text-gray-800">Galerie</p>
                        <Galerie/>
    </div>*/}


                </div>

                <div className="col-span-12 p-4 md:col-span-6">
                    <div className="mb-10">
                        {/* <div>
                            <span className="text-xl font-semibold text-gray-800">Titre : </span>
                            <span className="text-gray-600 ">{detail.title}</span>
                        </div> */}
                        <div>
                            <span className="text-xl font-semibold text-gray-800">Type : </span>
                            <span className="text-gray-600 ">{detail.type[0].name}</span>
                        </div>
                        <div>
                            <span className="text-xl font-semibold text-gray-800">Prix : </span>
                            <span className="text-gray-600 ">{detail.price} XAF / {detail.periodPrice}</span>
                        </div>

                        <div>
                            <span className="text-xl font-semibold text-gray-800">Adresse : </span>
                            <span className="text-gray-600 ">{detail.address}, {detail.town}, {detail.country}</span>
                        </div>

                        <div className="m-2">

                        </div>

                    </div>

                    <div className="mb-10">
                        <span className="pl-2 pr-2 font-bold text-center text-purple-800 bg-purple-100 rounded-xl" style={{ width: 100 }}>Meublé</span>
                        <span className="pl-3 pr-3 ml-2 mr-2 font-bold text-center text-green-800 bg-green-100 rounded-xl">Libre</span>

                        <div className="flex items-center justify-between m-5">
                            <span >
                                <FontAwesomeIcon icon={Icons.bed} className="mr-2 text-purple-800 " />
                                <p className="text-gray-600 ">4 lits</p>
                            </span>

                            <span>
                                <FontAwesomeIcon icon={Icons.bath} className="mr-2 text-purple-800 " />
                                <p className="text-gray-600 ">2 salles de bains</p>
                            </span>

                            <span>
                                <FontAwesomeIcon icon={Icons.car} className="mr-2 text-purple-800 " />
                                <p className="text-gray-600 ">Parking</p>
                            </span>
                        </div>

                    </div>

                    <div className="mb-10 ">
                        <p className="mb-5 text-xl font-semibold text-gray-800 ">Ce que propose propose ce logement en plus</p>

                        <li className="text-gray-600 ">Connexion wifi</li>
                        <li className="text-gray-600 ">Climatisation</li>
                        <li className="text-gray-600 ">Cuisine equipée</li>
                        <li className="text-gray-600 ">Parking</li>

                    </div>

                    <div className="mb-10">
                        <p className="mb-5 text-xl font-semibold text-gray-800">Description</p>
                        <p className="text-gray-700 ">{detail.desc}</p>
                    </div>

                    <div className="flex justify-center">
                        <button className="self-center w-full p-3 text-center text-white align-middle transition duration-150 bg-purple-800 rounded-lg cursor-pointer hover:bg-purple-700">Modifer</button>
                    </div>

                </div>

                <div className="col-span-12 p-8 ">
                    <p className="mb-5 text-xl font-bold text-gray-800">Galerie</p>

                    {
                        detail.Galeries.length > 0 ? (
                            <Galerie galeries={detail.Galeries}/>

                        ) :
                            (
                                <div className="flex items-center justify-center h-64 text-center text-gray-600">
                                    <p className="text-xl font-medium">Aucune galerie trouvé.</p>
                                </div>
                            )
                    }

                </div>

            </div>
        </>
    )
}