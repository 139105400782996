import { Link, useNavigate } from "react-router-dom"
import Pagination from "../../Components/Pagination"
import { MyContext } from "../../Components/Context";
import { useContext } from "react";
import SearchField from "../../Components/Forms/SearchField";


export default function Annonce({annonces}){


    const navigate = useNavigate();
    const {actifItem, setActifItem} = useContext(MyContext);
    
    return (
        <div className="px-4 md:px-8">
            <div className="max-w-lg">
                <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">
                    Annonces
                </h3>
                {/* <p className="mt-2 text-gray-600">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p> */}
            </div>
            <div className="mt-12 overflow-x-auto border rounded-lg shadow-sm">
                <table className="w-full text-sm text-left table-auto">
                    <thead className="font-medium text-gray-600 border-b bg-gray-50">
                        <tr>
                            <th className="px-6 py-3">Id</th>
                            <th className="px-6 py-3">Titre</th>
                            <th className="px-6 py-3">Ajouté le</th>
                            <th className="px-6 py-3">Prix</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                        {
                            annonces.map((item, idx) => (
                                
                                    <tr key={idx} className="w-full text-left transition rounded cursor-pointer hover:bg-gray-200 duration-900"
                                        onClick={()=>{
                                            navigate(`/annonce/details/${item.id}`)
                                            setActifItem('Annonces')
                                        }}
                                    >
                                        
                                            <td className="flex items-center px-6 py-3 gap-x-3 whitespace-nowrap">
                                                <img alt="" src={item.picture ? `${process.env.REACT_APP_API_PICTURE_URL}${item.picture}` : "https://randomuser.me/api/portraits/men/86.jpg"} className="w-10 h-10 rounded-full" />
                                                <div>
                                                    <span className="block text-sm font-medium text-gray-700">{item.id}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">{item.title}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{item.price} XAF/{item.periodePrice}</td>
                                    </tr>
                                
                            ))
                        }
                    </tbody>
                </table>
            </div>
            
            {/* <Pagination/> */}
        </div>
    )
}