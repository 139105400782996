import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icons from "../utils/icons"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ProfilMenu from "../Screen/Profil/profilMenu"
import { Popover } from "flowbite-react"
import { AuthContext } from "../context/AuthContext"
import { useContext } from "react"

const AppBar = ({ openDrawer, setDrawer }) => {

    const {user} = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className="flex items-center justify-between h-16 dark:bg-gray-800">
            <div className="flex items-center justify-center px-4 ">
                <button className="p-2 text-gray-500 rounded focus:outline-none focus:text-gray-700 hover:bg-purple-900" onClick={() => setDrawer(!openDrawer)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500 dark:text-white hover:text-white" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
                <input className="w-full px-4 py-2 mx-4 border rounded-md" type="text" placeholder="Search" />
            </div>

            <Popover
                aria-labelledby="profil-popover"
                content={<ProfilMenu visible={showMenu} setVisible={setShowMenu} id={"profil-popover"} />}
                arrow={false}

            >

                <div className="flex items-center pr-4">

                    <div
                        className="flex items-center rounded-full cursor-pointer ring-2 ring-purple-600"
                        onClick={toggleMenu}>
                        <img class=" w-10 h-10 rounded-full" src={user?.picture || "https://i.pinimg.com/236x/ee/90/ba/ee90ba08c2e6ead029cf5e84a5d186e7.jpg"} alt="Admin" />
                    </div>



                </div>

            </Popover>


        </div>
    )
};

export default AppBar