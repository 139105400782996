import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../utils/icons";
import { Outlet, useNavigate } from "react-router-dom";

export default function DetailsPlainte({type = 'res'}) {

    const navigate = useNavigate();

    return (
        <div className=" grid grid-cols-12">
            <div className="border-r-2 border-gray-200 col-span-4  max-lg:hidden">
                <Outlet/>
            </div>

            <div className=" col-span-8 max-lg:col-span-12 p-10">
                <div className="max-w-lg">
                    <div>
                        <div>
                           <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                                Titre
                            </h3>
                            <p className="text-gray-600 mt-2">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p> 
                        </div>

                        <div>
                            
                        </div>
                        

                    </div>
                    

                    <div className="text-gray-800 text-normal font-bold py-4">
                        <FontAwesomeIcon icon={Icons.calendar} className=" text-purple-800"/>
                        <span className=" text-purple-800 font-bold px-4 text-sm">14 janv 2024 - 21 Janv 2024</span>
                    </div>
                    

                   

                </div>

                <div className=" bg-gray-50 border-2 border-gray-200 p-5 rounded-xl mx-4 shadow-sm">
                    <p>Nostrud cillum duis velit aliqua Lorem laborum commodo proident ut dolore. Do ullamco aliquip ipsum fugiat eiusmod elit. Nostrud exercitation nisi quis et irure non fugiat qui id cillum mollit nisi labore.

                        Quis sint laborum pariatur laborum sunt ipsum Lorem ipsum reprehenderit nisi. Commodo qui reprehenderit eu tempor sint id ea magna ad cillum est quis labore. Enim tempor reprehenderit incididunt id proident. Laborum incididunt anim ullamco officia do voluptate adipisicing sint amet laborum ullamco id fugiat. Ut nostrud nulla aliqua incididunt amet est. In minim labore labore cupidatat Lorem consequat ipsum do velit exercitation. Esse minim ipsum occaecat amet do excepteur aute qui incididunt eu voluptate commodo proident cillum.

                        Aliqua incididunt exercitation dolor esse dolor eiusmod. Lorem cillum duis tempor deserunt eu ad minim. Aliqua consectetur ea laboris reprehenderit eiusmod nisi exercitation reprehenderit in quis commodo. Nostrud exercitation ut ipsum et esse. Esse in ut eu sit. Dolor commodo aute aute cupidatat occaecat consectetur ea laborum occaecat dolore eiusmod amet incididunt. Reprehenderit deserunt labore qui aute mollit non quis.

                        Id velit sit cupidatat irure dolore eu. Nostrud dolor irure ipsum ex quis voluptate sint officia incididunt occaecat irure. Tempor proident mollit sunt ut eu ex duis ut. Nulla laboris officia deserunt id sint consequat quis esse quis anim reprehenderit exercitation.
                    </p>
                <div className=" flex flex-row items-center justify-between pt-4">

                    <div className="text-gray-800 text-normal font-bold ">
                        <FontAwesomeIcon icon={Icons.clip} className=" text-purple-800"/> 
                        {type === 'res' && <span onClick={()=>navigate('reservation')}  className=" text-purple-800 font-bold px-4 cursor-pointer hover:text-purple-900">RSV-TYTF-HUHGF-POR</span>}
                        {type === 'anc' && <span onClick={()=>navigate('reservation')}  className=" text-purple-800 font-bold px-4 cursor-pointer hover:text-purple-900">RSV-TYTF-HUHGF-POR</span>}
                        {type === 'pai' && <span onClick={()=>navigate('reservation')}  className=" text-purple-800 font-bold px-4 cursor-pointer hover:text-purple-900">RSV-TYTF-HUHGF-POR</span>}
                        {type === 'pro' && <span onClick={()=>navigate('reservation')}  className=" text-purple-800 font-bold px-4 cursor-pointer hover:text-purple-900">RSV-TYTF-HUHGF-POR</span>}
                        {type === 'cli' && <span onClick={()=>navigate('reservation')}  className=" text-purple-800 font-bold px-4 cursor-pointer hover:text-purple-900">RSV-TYTF-HUHGF-POR</span>}

                    </div>

                    <span className=" bg-gray-800 hover:bg-gray-900 hover:ring-4 hover:ring-gray-300 cursor-pointer text-sm py-2 px-3 rounded-lg text-center shadow text-white">
                        Cloturée
                        <FontAwesomeIcon icon={Icons.check} color="white" className="mx-2"/>
                    </span>
                </div>

                </div>
                   





                

                        

                <div className="lg:hidden flex">
                    <Outlet/>
                </div>


                <div>
                    <textarea type= "text" className="w-full rounded-lg mt-20 h-40 border-1 border-gray-300 shadow"/>
                        <div className=" bg-purple-800 hover:bg-purple-900 active:ring-4 active:ring-purple-300 cursor-pointer w-40 p-2 rounded-lg text-center shadow text-white my-4">Envoyer</div>
                    
                </div>
            </div>
        </div>
    );
}