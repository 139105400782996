import React from 'react';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    const startItem = (currentPage - 1) * 10 + 1;
    const endItem = Math.min(currentPage * 10, totalPages * 10);

    return (
        <div className="max-w-screen-xl px-4 mx-auto mt-12 text-gray-600 md:px-8">
            <div className="items-center justify-center hidden text-sm md:flex" style={{ gap: 25 }}>
                <div>
                    AFFICHAGE DE {startItem} - {endItem} SUR {totalPages * 10}
                </div>
                <div className="flex items-center gap-12" aria-label="Pagination">
                    <button
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`hover:text-indigo-600 ${currentPage === 1 ? 'cursor-not-allowed text-gray-400' : ''}`}
                    >
                        Précédent
                    </button>
                    <ul className="flex items-center gap-1">
                        {pages.map((page) => (
                            <li key={page}>
                                <button
                                    onClick={() => onPageChange(page)}
                                    className={`px-3 py-2 rounded-lg duration-150 hover:text-white hover:bg-indigo-600 ${currentPage === page ? 'bg-indigo-600 text-white font-medium' : ''}`}
                                >
                                    {page}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`hover:text-indigo-600 ${currentPage === totalPages ? 'cursor-not-allowed text-gray-400' : ''}`}
                    >
                        Suivant
                    </button>
                </div>
            </div>
            <div className="flex items-center justify-between text-sm font-medium text-gray-600 md:hidden">
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 duration-150 border rounded-lg hover:bg-gray-50"
                >
                    Précédent
                </button>
                <div className="font-medium text-center">
                    AFFICHAGE DE {startItem} - {endItem} SUR {totalPages * 10}
                </div>
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 duration-150 border rounded-lg hover:bg-gray-50"
                >
                    Suivant
                </button>
            </div>
        </div>
    );
}
