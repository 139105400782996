import React, { useEffect } from "react";

const AlertMessage = ({ message, type, onClose }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose();
            }, 5000); 

            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <div className={`relative p-3 mt-4 border rounded-md ${type === 'error' ? 'text-red-700 bg-red-100 border-red-400' : 'text-green-700 bg-green-100 border-green-400'}`}>
            <div className={`absolute top-0 left-0 w-full h-full ${type === 'error' ? 'bg-red-200' : 'bg-green-200'} rounded-md animate-progress-bar`}></div>
            <p className="relative z-10 text-center">{message}</p>
        </div>
    );
};

export default AlertMessage;
