import Comments from "../Comments/Comments";

export default function Commentaire(commentaires){
    return (
        <div className="px-4 py-10 md:px-8">
        <div className="max-w-lg">
                    <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">
                        Commentaires
                    </h3>
                </div>
            <div className="grid gap-4 p-8 py-10 pt-20 lg:gap-8 md:grid-cols-3">
                <div className="grid gap-4 p-8 py-10 pt-20 lg:gap-8 md:grid-cols-3">
                {commentaires && commentaires.length > 0 ? (
                    commentaires.map((commentaire, index) => (
                        <Comments key={index} commentaire={commentaire} />
                    ))
                ) : (
                    <p className="col-span-3 text-center text-gray-500">Aucun commentaire trouvé.</p>
                )}
            </div>

            </div>
        </div>
    )
}