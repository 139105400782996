import Appartcard from "./AppartCard";

export default function ListAppart({nb=[0, 1, 2, 4, 5, 6, 7, 8]}){
    return (
        <>
            <div className="max-w-lg p-4">
                <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                    Appartement
                </h3>
                <p className="text-gray-600 mt-2">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>

                
            </div>
            <div className=" grid grid-cols-8 py-4 gap-6">
                {
                    nb.map((item, idx)=>(
                        <div key={idx} className="col-span-8 md:col-span-4 lg:col-span-3 xl:col-span-2">
                            <Appartcard id={idx}/>
                        </div>
                    ))
                }
            </div>
        </>
    )
}