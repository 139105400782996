import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const RoleBasedRoute = ({ allowedRoles, children }) => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; // Ou un spinner de chargement
  }

  if (!user || !allowedRoles.includes(user.role)) {
    return (
      <Navigate 
        to={user ? location.state?.from?.pathname || "/" : "/login"} 
        state={{ from: location, message: 'Vous devez être superadmin pour accéder à cet espace' }} 
      />
    );
  }

  return children ? children : <Outlet />;
};

export default RoleBasedRoute;
