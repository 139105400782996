import State from "../Stats/State";

export default function PrOprietaire({ observed, detail }) {
    // if (!detail) return <div>Loading...</div>;

    return (
        <div className="p-4 md:p-10">
            <div className="max-w-lg mb-8 md:mb-16">
                <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">Propriétaire</h3>
                <p className="mt-2 text-gray-600">
                    {detail.owner.client.bio ? detail.owner.client.bio : 'Aucune description du propriétaire'}
                </p>
            </div>

            <div className="flex flex-col items-center gap-4 md:flex-row md:gap-10">
                <div className={`grid-cols-4 md:grid-cols-2 ${observed === 'Proprietaire' && ' animate-fade-right animate-delay-700'}`}>
                    <img
                        alt={detail.owner.client.firstname}
                        src={detail.owner.client.picture ? `${process.env.REACT_APP_API_PICTURE_URL}${detail.owner.client.picture}` : "https://randomuser.me/api/portraits/men/86.jpg"}
                        className="w-32 h-32 rounded-full md:w-48 md:h-48"
                    />
                </div>

                <div className={`grid-cols-4 md:grid-cols-2 ${observed === 'Proprietaire' && ' animate-fade-left animate-delay-700'}`}>
                    <p className="text-lg font-bold text-gray-800 sm:text-2xl">{detail.owner.client.firstname} {detail.owner.client.lastname}</p>
                    {/* <p className="mt-1 text-gray-600">Financier chez UBA</p> */}
                </div>
            </div>

            <div className="grid items-start grid-cols-12 gap-6 mt-8 md:mt-20">
                <div className={`col-span-12 lg:col-span-6 ${observed === 'Proprietaire' && ' animate-fade-down animate-delay-200'}`}>
                    <div className="space-y-2">
                        <div>
                            <span className="mr-2 text-lg font-medium text-gray-800">Téléphone :</span>
                            <span className="text-gray-600">{detail.owner.client.phone}</span>
                        </div>

                        <div>
                            <span className="mr-2 text-lg font-medium text-gray-800">Email :</span>
                            <span className="text-gray-600">{detail.owner.client.email}</span>
                        </div>

                        <div>
                            <span className="mr-2 text-lg font-medium text-gray-800">Adresse :</span>
                            <span className="text-gray-600">1454 Rue Malanda, {detail.owner.client.city}, {detail.owner.client.country}</span>
                        </div>

                        <div>
                            <span className="mr-2 text-lg font-medium text-gray-800">Appréciation :</span>
                            <span className="px-3 py-1 font-bold text-green-800 bg-green-200 rounded-xl">Good</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 mt-8 md:flex-row">
                        <div className="w-full p-3 text-center text-white transition duration-150 bg-purple-800 rounded-lg cursor-pointer hover:bg-purple-600">
                            <p>Modifier</p>
                        </div>

                        <div className="w-full p-3 text-center text-white transition duration-150 bg-gray-800 rounded-lg cursor-pointer hover:bg-gray-600">
                            <span>Contacter</span>
                        </div>
                    </div>
                </div>

                {/* <div className="col-span-12 lg:col-span-6">
                    <h3 className="mb-6 text-lg font-bold text-gray-800 sm:text-xl md:text-2xl">Rapports</h3>
                    <div className="grid grid-cols-3 gap-4 lg:grid-cols-2">
                        <div className={`grid-cols-1 ${observed === 'Proprietaire' && 'animate-fade-up animate-delay-200'}`}>
                            <State value={detail.annonceCount} name={'Annonce'} state={''} />
                        </div>

                        <div className={`grid-cols-1 ${observed === 'Proprietaire' && 'animate-fade-up animate-delay-200'}`}>
                            <State value={detail.paiements.count} name={'Paiements'} state={''} />
                        </div>

                        <div className={`grid-cols-1 ${observed === 'Proprietaire' && 'animate-fade-up animate-delay-200'}`}>
                            <State value={detail.reservationCount} name={'Réservation'} state={''} />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
