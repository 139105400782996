import api from "../../api/api";
import { useState, useEffect } from "react";
import Last from "../../Components/Reports/Last";
import Discrease from "../../Components/Stats/Discrease";
import Increase from "../../Components/Stats/Increase";
import State from "../../Components/Stats/State";
import DernieresReservation from "../../Components/Tableaux/10Reserv";
import ListePlaintes from "../../Components/Tableaux/listePlaintes";
import DerniersPaiement from "../../Components/Tableaux/10Payments";
import DernieresAnnonces from "../../Components/Tableaux/10Annonces";
import Plaintes from "../Plaintes/plaintes";
import TablePlaintes from "../../Components/Tableaux/Plaintes";

const Dash = ({ actifItem, setActifItem }) => {
    const [currentStats, setCurrentStats] = useState({
        clientCount: 0,
        announceCount: 0,
        paiementCount: 0,
        bookingCount: 0,
        ownerCount: 0,
        complaintCount: 0,
    });

    const [previousStats, setPreviousStats] = useState({
        clientCount: 0,
        announceCount: 0,
        paiementCount: 0,
        bookingCount: 0,
        ownerCount: 0,
        complaintCount: 0,
    });

    useEffect(() => {
        const getStatistics = async () => {
            try {
                const response = await api.get('/getStatistics');
                console.log('Réponse du backend:', response.data);
                setPreviousStats(response.data.previousStats);
                setCurrentStats(response.data.currentStats);
            } catch (err) {
                console.error('Erreur lors de la récupération des statistiques :', err);
            }
        };

        getStatistics();
        const interval = setInterval(getStatistics, 60000); // Met à jour toutes les minutes
        return () => clearInterval(interval); // Nettoie l'intervalle lors du démontage du composant
    }, []);

    const calculateChange = (current, previous) => {
        if (previous === 0) {
            return current === 0 ? '0%' : '+100%'; // Affiche +100% si current > 0 et 0% si current est aussi 0
        }
        const change = ((current - previous) / previous) * 100;
        return `${change > 0 ? '+' : ''}${change.toFixed(2)}%`;
    };

    return (
        <div className="grid h-screen grid-cols-12 gap-4 px-4 py-20">
            <div className="col-span-12 lg:col-span-8">
                <div className="grid gap-4 lg:gap-8 md:grid-cols-12 animate-fade-up">
                    <div className="col-span-6">
                        {currentStats.clientCount > previousStats.clientCount ? (
                            <Increase 
                                nom={'Clients'} 
                                value={currentStats.clientCount} 
                                changeValue={calculateChange(currentStats.clientCount, previousStats.clientCount)} 
                                className={'relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5'} 
                            />
                        ) : (
                            <Discrease 
                                nom={'Clients'} 
                                value={currentStats.clientCount} 
                                changeValue={calculateChange(currentStats.clientCount, previousStats.clientCount)} 
                                className={"relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5"} 
                            />
                        )}
                        {currentStats.announceCount > previousStats.announceCount ? (
                            <Increase 
                                nom={'Annonces'} 
                                value={currentStats.announceCount} 
                                changeValue={calculateChange(currentStats.announceCount, previousStats.announceCount)} 
                                className={'relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5'} 
                            />
                        ) : (
                            <Discrease 
                                nom={'Annonces'} 
                                value={currentStats.announceCount} 
                                changeValue={calculateChange(currentStats.announceCount, previousStats.announceCount)} 
                                className={"relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5"} 
                            />
                        )}
                        {currentStats.paiementCount > previousStats.paiementCount ? (
                            <Increase 
                                nom={'Paiements'} 
                                value={currentStats.paiementCount} 
                                changeValue={calculateChange(currentStats.paiementCount, previousStats.paiementCount)} 
                                className={'relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5'} 
                            />
                        ) : (
                            <Discrease 
                                nom={'Paiements'} 
                                value={currentStats.paiementCount} 
                                changeValue={calculateChange(currentStats.paiementCount, previousStats.paiementCount)} 
                                className={"relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5"} 
                            />
                        )}
                    </div>
                    <div className="col-span-6">
                        {currentStats.bookingCount > previousStats.bookingCount ? (
                            <Increase 
                                nom={'Reservations'} 
                                value={currentStats.bookingCount} 
                                changeValue={calculateChange(currentStats.bookingCount, previousStats.bookingCount)} 
                                className={'relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5'} 
                            />
                        ) : (
                            <Discrease 
                                nom={'Reservations'} 
                                value={currentStats.bookingCount} 
                                changeValue={calculateChange(currentStats.bookingCount, previousStats.bookingCount)} 
                                className={"relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5"} 
                            />
                        )}
                        {currentStats.ownerCount > previousStats.ownerCount ? (
                            <Increase 
                                nom={'Propriétaires'} 
                                value={currentStats.ownerCount} 
                                changeValue={calculateChange(currentStats.ownerCount, previousStats.ownerCount)} 
                                className={'relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5'} 
                            />
                        ) : (
                            <Discrease 
                                nom={'Propriétaires'} 
                                value={currentStats.ownerCount} 
                                changeValue={calculateChange(currentStats.ownerCount, previousStats.ownerCount)} 
                                className={"relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5"} 
                            />
                        )}
                        {currentStats.complaintCount > previousStats.complaintCount ? (
                            <Increase 
                                nom={'Plaintes'} 
                                value={currentStats.complaintCount} 
                                changeValue={calculateChange(currentStats.complaintCount, previousStats.complaintCount)} 
                                className={'relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5'} 
                            />
                        ) : (
                            <Discrease 
                                nom={'Plaintes'} 
                                value={currentStats.complaintCount} 
                                changeValue={calculateChange(currentStats.complaintCount, previousStats.complaintCount)} 
                                className={"relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800 mb-5"} 
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="col-span-12 lg:col-span-4 animate-fade-down">
                <Last />
            </div>
            <div className="col-span-12 p-8 px-10 py-10">
                <p className="text-xl font-bold text-gray-800 sm:text-2xl">Reservations</p>
                <div className="grid gap-4 p-8 px-10 py-10 md:grid-cols-5">
                    <State value={25} state={'res'} name={'En cours'} />
                    <State value={1} state={'echec'} name={'Echouée'} />
                </div>
            </div>
            <div className="col-span-12 p-8 px-10 py-10">
                <TablePlaintes />
            </div>
            <div className="col-span-12 p-8 px-10 py-10">
                <p className="text-xl font-bold text-gray-800 sm:text-2xl">Dernieres reservations</p>
                <DernieresReservation />
            </div>
            <div className="col-span-12 p-8 px-10 py-10">
                <p className="text-xl font-bold text-gray-800 sm:text-2xl">Derniers paiements</p>
                <DerniersPaiement />
            </div>
            <div className="col-span-12 p-8 px-10 py-10">
                <p className="text-xl font-bold text-gray-800 sm:text-2xl">Dernieres Annonces</p>
                <DernieresAnnonces />
            </div> */}
        </div>
    );
};

export default Dash;
