export default function Apercu({detail}){
    if(!detail) return null;
    console.log('detail is: ', detail)
    return (
        <div style={{flex : 1, justifyContent : 'flex-start', alignItems : 'center',padding : 20}}>
            <p className="mt-3 text-xl font-bold text-gray-800 sm:text-2xl">N° Annonce</p>
            <p className="mt-2 text-gray-600 ">{detail.id}</p>
                <h5 className="mt-3 text-xl font-bold text-gray-800 sm:text-2xl">Date de creation </h5>
                <p className="mt-2 text-gray-600 ">{new Date(detail.createdAt).toLocaleDateString()}</p>
                <h5 className="mt-3 text-xl font-bold text-gray-800 sm:text-2xl">Durée </h5>
                <p className="mt-2 text-gray-600 ">1 ans </p>
                <p className="text-xl font-semibold text-gray-800 sm:text-2xl">Statut</p>
                {
                    detail.isVisible ? 
                    <p className="pt-1 pb-1 pl-2 pr-2 mt-2 font-bold text-center text-blue-800 bg-blue-100 rounded-xl" style={{width : 100, fontSize : 14}}>Publié</p>
                        :
                    <p className="pt-1 pb-1 pl-2 pr-2 mt-2 font-bold text-center text-red-800 bg-red-100 rounded-xl" style={{width : 100, fontSize : 14}}>Masqué</p>
                }
        </div>
    )
}