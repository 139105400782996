import { useState } from "react"

export default function Facturation(){

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    return(
       <>
            <div className="grid grid-cols-4 md:grid-cols-12 gap-4 p-4 ">
                <div className="col-span-2 p-4 size-32 text-center cursor-pointer rounded border border-gray-400" onClick={()=>{setOpen(true) ; setData({'name' : 'paypal'})}}>
                    <img src="https://cdn-icons-png.flaticon.com/128/174/174861.png" alt="paypal logo"/>
                </div>
                <div className="col-span-2  p-4 size-32 cursor-pointer rounded border border-gray-400" onClick={()=>{setOpen(true) ; setData({'name' : 'MTN'})}}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/New-mtn-logo.jpg/600px-New-mtn-logo.jpg?20220217143058" alt="mtn logo"/>
                </div>
                <div className="col-span-2  p-4 size-32 cursor-pointer rounded border border-gray-400" onClick={()=>{setOpen(true) ; setData({'name' : 'Airtel'})}}>
                    <img src="https://seeklogo.com/images/A/airtel-logo-593C498F73-seeklogo.com.png" alt="airtel logo"/>
                </div>
                <div className="col-span-2  p-4 size-32 cursor-pointer rounded border border-gray-400" onClick={()=>{setOpen(true) ; setData({'name' : 'VISA'})}}>
                    <img src="https://logowik.com/content/uploads/images/visa-new-20215093.jpg" alt="visa logo"/>
                </div>
                
            </div>


            {open && <div id="modal" class="flex inset-0 overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center  bg-gray-800 bg-opacity-75 animate-fade">


                <div className=" relative p-4 max-w-md w-full m-auto bg-white shadow-lg rounded-lg">
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <p className=" text-lg font-semibold text-gray-700">{data.name}</p>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=>setOpen(false)}>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            
                        </button>
                    </div>
    
                    <form className="py-4 px-6" action="" method="POST">
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2" for="name">
                                Nom
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="name" type="text" value={data.name}/>
                        </div>
                        
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2" for="email">
                                Email
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email" type="email" />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2" for="apikey">
                                APIkey
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="apikey" type="apikey" />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2" for="services">
                                Services
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="privilege" name="privilege">
                                <option value="">Entrez le services</option>
                                <option value="haircut">services</option>
                                
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2" for="date">
                                Date subscription
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="date" type="date"/>
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2" for="date">
                                Date expiration
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="date" type="date"/>
                        </div>
                        
                        
                        <div className="flex items-center justify-center mb-4">
                            <button
                                className="w-full bg-purple-700 text-white py-2 px-4 rounded hover:bg-purple-900 focus:outline-none focus:shadow-outline"
                                type="submit">
                                Subscrire
                            </button>
                        </div>

                    </form>
                </div>
            </div>}
        </>
     
        
    )
}