import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Icons from "../utils/icons";
import { useState } from "react";

const Drawer = ({open, setOpen, selectedItem, setSelectedItem})=>{
    
    return (
        <div className="flex-col w-64 bg-gray-800 md:flex" >
        
        <div className="flex flex-col flex-1 overflow-y-auto animate-fade-right">
            <nav className="flex-1 px-2 py-4 bg-gray-800">
                    
                <Link 
                    to='/' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg ${selectedItem === 'Dashboard' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Dashboard')}
                >
                    <FontAwesomeIcon icon={Icons.Dashboard} size={20} className="mr-4"/>
                    Dashboard
                </Link>

            

                <Link 
                    to='/client' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg ${selectedItem === 'Clients' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Clients')}
                >
                    <FontAwesomeIcon icon={Icons.user} size={20} className="mr-4"/>
                    Clients
                </Link>
                


                <Link 
                    to='/annonce' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg ${selectedItem === 'Annonces' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Annonces')}
                >
                    <FontAwesomeIcon icon={Icons.Horn} size={20} className="mr-4"/>
                    Annonces
                </Link>
                

                    
                <Link 
                    to='/proprietaires' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg ${selectedItem === 'Proprietaires' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Proprietaires')}
                >
                    <FontAwesomeIcon icon={Icons.proprio} size={20} className="mr-4"/>
                    
                    Proprietaires
                </Link>
                    

                <Link 
                    to='/reservation' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg ${selectedItem === 'Reservations' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Reservations')}
                >
                    <FontAwesomeIcon icon={Icons.HandshakeAngle} size={20} className="mr-4"/>
                    Reservations
                </Link>

                {/* <Link 
                    to='/plaintes' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg ${selectedItem === 'Plaintes' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Plaintes')}
                >
                    <FontAwesomeIcon icon={Icons.Ticket} size={20} className="mr-4"/>
                    Plaintes
                </Link> */}
              

                
                    
                <Link 
                    to='parametres/compte' 
                    className={`flex items-center px-4 py-2 my-4 text-gray-100 hover:bg-purple-900 rounded-lg  ${selectedItem === 'Parametres' && 'bg-purple-800'}`}
                    onClick={()=>setSelectedItem('Parametres')}
                >
                    <FontAwesomeIcon icon={Icons.Gear} size={20} className="mr-4"/>

                    Parametres
                </Link>
                
                
            </nav>
            
        </div>
    </div>
    )
}

export default Drawer;