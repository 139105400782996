import { Outlet, useNavigate, useParams } from "react-router-dom";
import Tab from "../../Components/Annonce/Tab";
import { useEffect, useRef, useState, useContext } from "react";
import api from "../../api/api";
import Reservation from "../../Components/Sections/Reservation";
import Paiement from "../../Components/Sections/Paiement";
import Annonce from "../Annonces/Annonce";
import PrOprietaire from "../../Components/Sections/Proprietaire";
import { MyContext } from "../../Components/Context";
import ListAppart from "../../Components/Sections/ListAppart";


export default function DetailsProprio() {
    const { id } = useParams();
    const [ownerDetails, setOwnerDetails] = useState(null);

    const [selectedTab, setSelectedTab] = useState("Apercu");
    const navigate = useNavigate();
    const tabItems = [
        "Apercu",
        "Annonces",
        "Reservations",
        "Paiements",
    ];

    const { actifItem, setActifItem } = useContext(MyContext);


    const apercuRef = useRef(null);
    const annonceRef = useRef(null);
    const paiementRef = useRef(null);
    const reservationRef = useRef(null);

    useEffect(() => {
        const getOwnerDetails = async () => {
            try {
                const response = await api.get(`/owners/${id}`);
                setOwnerDetails(response.data);
                console.log('res :',response.data)
            } catch (err) {
                console.error('Erreur lors de la récupération des détails du propriétaire !', err);
            }
        };

        getOwnerDetails();
    }, [id]);


    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    switch (entry.target) {
                        case apercuRef.current:
                            setSelectedTab("Apercu");
                            break;


                        case annonceRef.current:
                            setSelectedTab("Annonces");
                            break;

                        case paiementRef.current:
                            setSelectedTab("Paiements");
                            break;

                        case reservationRef.current:
                            setSelectedTab("Reservations");
                            break;

                        default:
                            break;
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (apercuRef.current) observer.observe(apercuRef.current);
        if (annonceRef.current) observer.observe(annonceRef.current);
        if (paiementRef.current) observer.observe(paiementRef.current);
        if (reservationRef.current) observer.observe(reservationRef.current);

        return () => {
            if (apercuRef.current) observer.unobserve(apercuRef.current);
            if (annonceRef.current) observer.unobserve(annonceRef.current);
            if (paiementRef.current) observer.unobserve(paiementRef.current);
            if (reservationRef.current) observer.unobserve(reservationRef.current);
        };
    }, []);


    const scrollToSection = (section) => {
        switch (section) {
            case "Apercu":
                apercuRef.current.scrollIntoView({ behavior: 'smooth' });
                break;


            case "Annonces":
                annonceRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            case "Paiements":
                paiementRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            case "Reservations":
                reservationRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            default:
                break;
        }
    };

    return (

        <div className="grid grid-cols-12 gap-4">

            <div className="h-full col-span-12 row-auto p-4 overflow-auto md: lg:fixed top-12">
                <Tab tabItems={tabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onTabClick={scrollToSection} />
            </div>
            <div className="col-span-12 md:col-span-10 md:col-start-3" style={{ borderLeftWidth: 2 }}>

                {
                    ownerDetails &&

                    <div ref={apercuRef}>
                        <PrOprietaire detail={ownerDetails} />
                    </div>
                }

                {
                    ownerDetails && (
                        <div ref={annonceRef}>
                            <Annonce annonces={ownerDetails.owner.annonce} />
                        </div>

                    )
                }
                {
                    ownerDetails &&
                    <div ref={reservationRef}>
                        <Reservation reservations={ownerDetails.owner.annonces} />
                    </div>
                }

                {
                    ownerDetails &&
                    <div ref={paiementRef}>
                        <Paiement paiements={ownerDetails.paiements}/>

                    </div>
                }



            </div>

        </div>
    )
}