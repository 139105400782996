import React, { useState, useEffect } from 'react';
import api from "../../api/api";
import AlertMessage from '../../Components/AlertMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPen, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Components/Loader';

export default function PushNotifications() {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [showModal, setShowModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);

    // Pagination and Filtering State
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        searchTerm: '',
    });

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const response = await api.get('/notifications', {
                    params: {
                        page,
                        pageSize,
                        ...filters,
                    }
                });
                setNotifications(response.data.notifications);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                console.error('Erreur lors de la récupération des notifications :', err);
                setAlert({ type: 'error', message: 'Erreur lors de la récupération des notifications.' });
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, [page, pageSize, filters]);

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleSearch = () => {
        setPage(1); // Reset to first page when applying filters
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleShowDetail = (notification) => {
        setSelectedNotification(notification);
        setShowDetailModal(true);
    };

    const handleSendNotification = async () => {
        setSending(true);
        try {
            await api.post('/notifications', { title, message });
            setAlert({ type: 'success', message: 'Notification envoyée avec succès.' });
            setShowModal(false);
            setTitle('');
            setMessage('');
            // Refresh the list after sending
            setPage(1); // Optionally reset to the first page
            setFilters({ startDate: '', endDate: '', searchTerm: '' });
        } catch (err) {
            console.error('Erreur lors de l\'envoi de la notification :', err);
            setAlert({ type: 'error', message: 'Erreur lors de l\'envoi de la notification.' });
        } finally {
            setSending(false);
        }
    };

    const handleDeleteNotification = async (id) => {
        try {
            await api.delete(`/notifications/${id}`);
            setAlert({ type: 'success', message: 'Notification supprimée avec succès.' });
            setPage(1);
            setFilters({ startDate: '', endDate: '', searchTerm: '' });
        } catch (err) {
            console.error('Erreur lors de la suppression de la notification :', err);
            setAlert({ type: 'error', message: 'Erreur lors de la suppression de la notification.' });
        }
    };

    const handleResendNotification = async (id) => {
        try {
            await api.post(`/notifications/${id}/resend`);
            setAlert({ type: 'success', message: 'Notification renvoyée avec succès.' });
        } catch (err) {
            console.error('Erreur lors du renvoi de la notification :', err);
            setAlert({ type: 'error', message: 'Erreur lors du renvoi de la notification.' });
        }
    };
    return (
        <div className="flex flex-col px-4 py-6 mx-4 md:mx-10">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="flex justify-between mb-4">
                        <h3 className="text-xl font-bold text-gray-800">Notifications Push</h3>
                        <button
                            onClick={() => setShowModal(true)}
                            className="flex items-center px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg shadow hover:bg-indigo-500"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                            Nouvelle Notification
                        </button>
                    </div>

                    {alert.message && (
                        <AlertMessage type={alert.type} message={alert.message} onClose={() => setAlert({})} />
                    )}

                    {/* Filter and Search Section */}
                    <div className="p-6 mb-4 bg-white rounded-lg shadow-lg">
                        <h4 className="mb-4 text-lg font-semibold text-gray-800">Filtrer les notifications</h4>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Date de début</label>
                                <input
                                    type="date"
                                    name="startDate"
                                    value={filters.startDate}
                                    onChange={handleFilterChange}
                                    className="w-full p-2 mt-1 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Date de fin</label>
                                <input
                                    type="date"
                                    name="endDate"
                                    value={filters.endDate}
                                    onChange={handleFilterChange}
                                    className="w-full p-2 mt-1 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Rechercher</label>
                                <input
                                    type="text"
                                    name="searchTerm"
                                    value={filters.searchTerm}
                                    onChange={handleFilterChange}
                                    className="w-full p-2 mt-1 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Rechercher"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={handleSearch}
                                className="px-6 py-2 font-semibold text-white bg-indigo-600 rounded-lg shadow hover:bg-indigo-500"
                            >
                                Rechercher
                            </button>
                            <button
                                onClick={() => setFilters({ startDate: '', endDate: '', searchTerm: '' })}
                                className="px-6 py-2 ml-4 font-semibold text-gray-600 bg-gray-200 rounded-lg shadow hover:bg-gray-300"
                            >
                                Réinitialiser
                            </button>
                        </div>
                    </div>


                    <div className="p-6 bg-white rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold text-gray-800">Liste des notifications</h3>
                        {notifications.length === 0 ? (
                            <div className="flex justify-center py-10">
                                <p className="text-lg font-semibold text-gray-500">Aucune notification pour le moment.</p>
                            </div>
                        ) : (
                            <ul className="mt-4 space-y-4">
                                {notifications.map((notification) => (
                                    <li key={notification.id} className="flex items-start p-4 rounded-lg shadow-sm bg-gray-50">
                                        <div className="flex-1">
                                            <h4 className="font-semibold text-gray-700">{notification.title}</h4>
                                            <p className="text-gray-500">{notification.message}</p>
                                            <p className="mt-1 text-sm text-gray-400">{new Date(notification.createdAt).toLocaleString()}</p>
                                        </div>
                                        <div className="flex flex-col items-end justify-between">
                                            <button
                                                onClick={() => handleShowDetail(notification)}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                <FontAwesomeIcon icon={faPen} className="mr-2" /> Détails
                                            </button>
                                            <button
                                                onClick={() => handleResendNotification(notification.id)}
                                                className="mt-2 text-yellow-600 hover:text-yellow-800"
                                            >
                                                <FontAwesomeIcon icon={faPen} className="mr-2" /> Renvoyer
                                            </button>
                                            <button
                                                onClick={() => handleDeleteNotification(notification.id)}
                                                className="mt-2 text-red-600 hover:text-red-800"
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Supprimer
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {/* Pagination Section */}
                        <div className="flex items-center justify-between mt-6">
                            <button
                                onClick={() => handlePageChange(page - 1)}
                                disabled={page <= 1}
                                className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
                            >
                                Précédent
                            </button>
                            <span>Page {page} sur {totalPages}</span>
                            <button
                                onClick={() => handlePageChange(page + 1)}
                                disabled={page >= totalPages}
                                className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
                            >
                                Suivant
                            </button>
                        </div>
                    </div>

                    {/* Modal for New Notification */}
                    {showModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                            <div className="p-8 bg-white rounded-lg shadow-lg">
                                <h2 className="text-lg font-semibold text-gray-800">Rédiger une nouvelle notification</h2>
                                <div className="mt-4">
                                    <label className="block text-sm font-medium text-gray-700">Titre</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                                        placeholder="Titre de la notification"
                                    />
                                </div>
                                <div className="mt-4">
                                    <label className="block text-sm font-medium text-gray-700">Message</label>
                                    <textarea
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                                        rows="5"
                                        placeholder="Message de la notification"
                                    />
                                </div>
                                {alert.message && (
                                    <div className={`mt-4 p-4 rounded-md ${alert.type === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
                                        {alert.message}
                                    </div>
                                )}
                                <div className="flex justify-end mt-6 space-x-4">
                                    <button
                                        onClick={() => setShowModal(false)}
                                        className="px-4 py-2 font-medium text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        onClick={handleSendNotification}
                                        disabled={sending || !title || !message}
                                        className="px-4 py-2 font-medium text-white bg-indigo-600 rounded-lg shadow hover:bg-indigo-500 disabled:opacity-50"
                                    >
                                        {sending ? 'Envoi...' : 'Envoyer'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Modal for Notification Details */}
                    {showDetailModal && selectedNotification && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                            <div className="p-8 bg-white rounded-lg shadow-lg">
                                <h2 className="text-lg font-semibold text-gray-800">Détails de la notification</h2>
                                <div className="mt-4">
                                    <p className="font-bold text-gray-700">Titre :</p>
                                    <p className="text-gray-600">{selectedNotification.title}</p>
                                </div>
                                <div className="mt-4">
                                    <p className="font-bold text-gray-700">Message :</p>
                                    <p className="text-gray-600">{selectedNotification.message}</p>
                                </div>
                                <div className="mt-4">
                                    <p className="font-bold text-gray-700">Date d'envoi :</p>
                                    <p className="text-gray-600">{new Date(selectedNotification.createdAt).toLocaleString()}</p>
                                </div>
                                <div className="flex justify-end mt-6 space-x-4">
                                    <button
                                        onClick={() => setShowDetailModal(false)}
                                        className="px-4 py-2 font-medium text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
