import React, { useState } from 'react';
import './profil.css';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export default function DetailProfil(){

    const [profile, setProfile] = useState({
        nom: 'NGASSAKI',
        prenom: 'Sylvia',
        email: 'ngassakisylvia@icloud.com',
        telephone: '555-555-555',
        privilege: 'admin',
        dateexp: '20/01/2022',
        datefab: '20/01/2022',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile({
          ...profile,
          [name]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Profil soumis:', profile);
      };
    
    return(
        <div className="grid grid-cols-12"> 
          <div className="bg-white overflow-hidden md:col-span-6 col-span-12">
              
              <form className="py-4 px-6" action="" method="POST">
              <div
                className="mx-auto flex justify-center w-[141px] h-[141px] bg-blue-300/20 rounded-full bg-[url('https://images.unsplash.com/photo-1438761681033-6461ffad8d80?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw4fHxwcm9maWxlfGVufDB8MHx8fDE3MTEwMDM0MjN8MA&ixlib=rb-4.0.3&q=80&w=1080')] bg-cover bg-center bg-no-repeat">

                <div className="bg-white/90 rounded-full w-6 h-6 text-center ml-28 mt-4">
                    <input type="file" name="profile" id="upload_profile" hidden required/>
                          <label for="upload_profile">
                            <svg data-slot="icon" className="w-6 h-5 text-blue-700" fill="none"
                                stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z">
                                </path>
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z">
                                </path>
                            </svg>
                        </label>
                </div>
              </div>
                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="name">
                          Nom
                      </label>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="name" type="text" placeholder="Entrez le nom"/>
                  </div>
                  
                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="name">
                          Nom utilisateur
                      </label>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="user" type="text" placeholder="Entrez le nom"/>
                  </div>


                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="firsname">
                          Prenoms
                      </label>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="firsname" type="text" placeholder="Entrez le prénom"/>
                  </div>
                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="email">
                          Email
                      </label>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="email" type="email" placeholder="Entrez l'email"/>
                  </div>
                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="phone">
                          telephone
                      </label>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="phone" type="tel" placeholder="Entrez le telephone"
                      />
                  </div>

                  <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2" for="phone">
                            Mot de passe
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="phone" type="tel" placeholder="Entrez le mot de passe" security={'true'}/>
                  </div>
                
                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="privilege">
                          Privilège
                      </label>
                      <select
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="privilege" name="privilege">
                          <option value="" defaultValue={true}>Entrez le privilege</option>
                          <option value="admin">admin</option>
                          
                      </select>
                  </div>

                  <div className="mb-4">
                      <label className="block text-gray-700 font-bold mb-2" for="privilege">
                          Question de sécurité
                      </label>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 mb-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="phone" type="tel" placeholder="Entrez la question"/>
                      <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="phone" type="tel" placeholder="Entrez la reponse"/>
                  </div>

                  <div className="flex items-center justify-center">
                      <button
                          className="w-full bg-purple-700 text-white py-2 px-4 rounded hover:bg-purple-900 focus:outline-none focus:shadow-outline"
                          type="submit">
                        Modifier
                      </button>
                    </div>

              </form>
          </div>

          <div className=' md:col-span-6 bg-slate-100 h-full p-6 col-span-12'>
                <p className='block text-gray-700 font-bold text-xl mb-16'>Parametres</p>

                
                <div>
                    <p className='block text-gray-700 font-bold mb-2'>Notifications</p>

                    <div className='m-6'>
                        <label className="block text-gray-700 font-bold mb-2" for="privilege">
                          Preference d'alerte
                        </label>
                        <select
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="privilege" name="privilege">
                            <option value="e-mail" defaultValue={true}>E-mail</option>
                            <option value="push">Push Notification</option>
                            <option value="muet">Muet</option>

                        </select>
                    </div>

                    
                </div>

                <div>
                    <p className='block text-gray-700 font-bold mb-2'>Preference de compte</p>

                    <div className='m-6'>
                        <label className="block text-gray-700 font-bold mb-2" for="privilege">
                          Langue
                        </label>
                        <select
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="privilege" name="privilege">
                            <option value="fr" defaultValue={true}>Francais</option>
                            <option value="en">Anglais</option>
                            <option value="es">Espagnol</option>
                            <option value="de">Allemand</option>
                            <option value="ch">Chinois</option>
                            <option value="jp">Japonais</option>
                            <option value="Pg">Portugais</option>
                        </select>
                    </div>

                    <div className='m-6'>
                        <label className="block text-gray-700 font-bold mb-2" for="privilege">
                          fuseau horaire
                        </label>
                        <select
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="timezone" name="timezone">

                            <option value="" defaultValue={true}>Entrez votre preference</option>
                            <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                            <option value="America/Adak">(GMT-10:00) Hawaii-Aleutian</option>
                            <option value="Etc/GMT+10">(GMT-10:00) Hawaii</option>
                            <option value="Pacific/Marquesas">(GMT-09:30) Marquesas Islands</option>
                            <option value="Pacific/Gambier">(GMT-09:00) Gambier Islands</option>
                            <option value="America/Anchorage">(GMT-09:00) Alaska</option>
                            <option value="America/Ensenada">(GMT-08:00) Tijuana, Baja California</option>
                            <option value="Etc/GMT+8">(GMT-08:00) Pitcairn Islands</option>
                            <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                            <option value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</option>
                            <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                            <option value="America/Dawson_Creek">(GMT-07:00) Arizona</option>
                            <option value="America/Belize">(GMT-06:00) Saskatchewan, Central America</option>
                            <option value="America/Cancun">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                            <option value="Chile/EasterIsland">(GMT-06:00) Easter Island</option>
                            <option value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</option>
                            <option value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</option>
                            <option value="America/Havana">(GMT-05:00) Cuba</option>
                            <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                            <option value="America/Caracas">(GMT-04:30) Caracas</option>
                            <option value="America/Santiago">(GMT-04:00) Santiago</option>
                            <option value="America/La_Paz">(GMT-04:00) La Paz</option>
                            <option value="Atlantic/Stanley">(GMT-04:00) Faukland Islands</option>
                            <option value="America/Campo_Grande">(GMT-04:00) Brazil</option>
                            <option value="America/Goose_Bay">(GMT-04:00) Atlantic Time (Goose Bay)</option>
                            <option value="America/Glace_Bay">(GMT-04:00) Atlantic Time (Canada)</option>
                            <option value="America/St_Johns">(GMT-03:30) Newfoundland</option>
                            <option value="America/Araguaina">(GMT-03:00) UTC-3</option>
                            <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                            <option value="America/Miquelon">(GMT-03:00) Miquelon, St. Pierre</option>
                            <option value="America/Godthab">(GMT-03:00) Greenland</option>
                            <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires</option>
                            <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                            <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                            <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                            <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                            <option value="Europe/Belfast">(GMT) Greenwich Mean Time : Belfast</option>
                            <option value="Europe/Dublin">(GMT) Greenwich Mean Time : Dublin</option>
                            <option value="Europe/Lisbon">(GMT) Greenwich Mean Time : Lisbon</option>
                            <option value="Europe/London">(GMT) Greenwich Mean Time : London</option>
                            <option value="Africa/Abidjan">(GMT) Monrovia, Reykjavik</option>
                            <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                            <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                            <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                            <option value="Africa/Algiers">(GMT+01:00) West Central Africa</option>
                            <option value="Africa/Windhoek">(GMT+01:00) Windhoek</option>
                            <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                            <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                            <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                            <option value="Africa/Blantyre">(GMT+02:00) Harare, Pretoria</option>
                            <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                            <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                            <option value="Asia/Damascus">(GMT+02:00) Syria</option>
                            <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                            <option value="Africa/Addis_Ababa">(GMT+03:00) Nairobi</option>
                            <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                            <option value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</option>
                            <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                            <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                            <option value="Asia/Yekaterinburg">(GMT+05:00) Ekaterinburg</option>
                            <option value="Asia/Tashkent">(GMT+05:00) Tashkent</option>
                            <option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                            <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                            <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                            <option value="Asia/Novosibirsk">(GMT+06:00) Novosibirsk</option>
                            <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                            <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                            <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                            <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                            <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                            <option value="Australia/Perth">(GMT+08:00) Perth</option>
                            <option value="Australia/Eucla">(GMT+08:45) Eucla</option>
                            <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                            <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                            <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                            <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                            <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                            <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                            <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                            <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                            <option value="Australia/Lord_Howe">(GMT+10:30) Lord Howe Island</option>
                            <option value="Etc/GMT-11">(GMT+11:00) Solomon Is., New Caledonia</option>
                            <option value="Asia/Magadan">(GMT+11:00) Magadan</option>
                            <option value="Pacific/Norfolk">(GMT+11:30) Norfolk Island</option>
                            <option value="Asia/Anadyr">(GMT+12:00) Anadyr, Kamchatka</option>
                            <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                        </select>
                    </div>


                    <div className='m-6'>
                        <label className="block text-gray-700 font-bold mb-2" for="privilege">
                          Format de date
                        </label>
                        <select
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="datetime_format" name="datetime_format">
                            <option value="" defaultValue={true}>Entrez votre preference</option>
                            <option value="YYYY-MM-DD HH:mm:ss">2024-06-11 14:23:45 (YYYY-MM-DD HH:mm:ss)</option>
                            <option value="DD/MM/YYYY HH:mm:ss">11/06/2024 14:23:45 (DD/MM/YYYY HH:mm:ss)</option>
                            <option value="MM/DD/YYYY HH:mm:ss">06/11/2024 14:23:45 (MM/DD/YYYY HH:mm:ss)</option>
                            <option value="YYYY-MM-DD">2024-06-11 (YYYY-MM-DD)</option>
                            <option value="DD/MM/YYYY">11/06/2024 (DD/MM/YYYY)</option>
                            <option value="MM/DD/YYYY">06/11/2024 (MM/DD/YYYY)</option>
                            <option value="YYYY/MM/DD">2024/06/11 (YYYY/MM/DD)</option>
                            <option value="DD.MM.YYYY">11.06.2024 (DD.MM.YYYY)</option>
                            <option value="MM.DD.YYYY">06.11.2024 (MM.DD.YYYY)</option>
                        </select>

                    </div>

                    <div className="flex items-center justify-center m-6">
                      <button
                          className="w-full bg-purple-700 text-white py-2 px-4 rounded hover:bg-purple-900 focus:outline-none focus:shadow-outline"
                          type="submit">
                        Modifier
                      </button>
                    </div>

                    

                    
                </div>
              

          </div>
 
        </div>

    )
}