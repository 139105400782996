import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import Loader from "../../Components/Loader";
import Tab from "../../Components/Annonce/Tab";
import ApercuReservation from "./Apercu";
import Appartement from "../../Components/Sections/Appartements";
import Proprietaire from "../../Components/Sections/Proprietaire";
import Paiement from "../../Components/Sections/Paiement";

export default function DetailsReservation() {
    const { id } = useParams();
    const [selectedTab, setSelectedTab] = useState("Apercu");
    const [reservationDetails, setReservationDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const tabItems = ["Apercu", "Appartement", "Proprietaire", "Paiements"];
    const apercuRef = useRef(null);
    const appartRef = useRef(null);
    const proprioRef = useRef(null);
    const paiementRef = useRef(null);

    useEffect(() => {
        const fetchReservationDetails = async () => {
            try {
                const response = await api.get(`/reservations/${id}`);
                setReservationDetails(response.data.reservation);
            } catch (err) {
                setError("Erreur lors de la récupération des détails de la réservation.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchReservationDetails();
    }, [id]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        };

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    switch (entry.target) {
                        case apercuRef.current:
                            setSelectedTab("Apercu");
                            break;
                        case appartRef.current:
                            setSelectedTab("Appartement");
                            break;
                        case proprioRef.current:
                            setSelectedTab("Proprietaire");
                            break;
                        case paiementRef.current:
                            setSelectedTab("Paiements");
                            break;
                        default:
                            break;
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (apercuRef.current) observer.observe(apercuRef.current);
        if (appartRef.current) observer.observe(appartRef.current);
        if (proprioRef.current) observer.observe(proprioRef.current);
        if (paiementRef.current) observer.observe(paiementRef.current);

        return () => {
            if (apercuRef.current) observer.unobserve(apercuRef.current);
            if (appartRef.current) observer.unobserve(appartRef.current);
            if (proprioRef.current) observer.unobserve(proprioRef.current);
            if (paiementRef.current) observer.unobserve(paiementRef.current);
        };
    }, []);

    const scrollToSection = (section) => {
        switch (section) {
            case "Apercu":
                apercuRef.current.scrollIntoView({ behavior: "smooth" });
                break;
            case "Appartement":
                appartRef.current.scrollIntoView({ behavior: "smooth" });
                break;
            case "Proprietaire":
                proprioRef.current.scrollIntoView({ behavior: "smooth" });
                break;
            case "Paiements":
                paiementRef.current.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    if (loading) return <Loader />;
    if (error) return <div>{error}</div>;

    return (
        <div className="relative grid grid-cols-12 gap-4">
            <div className="h-full col-span-12 row-auto p-4 overflow-auto md:lg:fixed top-12">
                <Tab
                    tabItems={tabItems}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    onTabClick={scrollToSection}
                />
            </div>
            <div className="col-span-12 md:col-span-10 md:col-start-3" style={{ borderLeftWidth: 2 }}>
                {
                    reservationDetails &&
                    <div ref={apercuRef}>
                        <ApercuReservation reservation={reservationDetails} />
                    </div>
                }
                {
                    reservationDetails &&
                    <div ref={appartRef}>
                        <Appartement detail={reservationDetails.annonce} />
                    </div>
                }
                {
                    reservationDetails &&
                    <div ref={proprioRef}>
                        <Proprietaire detail={reservationDetails.annonce} />
                    </div>
                }
                {
                    reservationDetails &&
                    <div ref={paiementRef}>
                        <Paiement paiement={reservationDetails} />
                    </div>
                }
            </div>
        </div>
    );
}
