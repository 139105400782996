import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import Pagination from '../../Components/Pagination';
import SearchField from '../../Components/Forms/SearchField';
import Loader from '../../Components/Loader';
import AlertMessage from '../../Components/AlertMessage';

export default function Reservation() {
    const navigate = useNavigate();
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ type: '', message: '' });

    // Pagination and Filtering State
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        searchTerm: '',
        status: '',
        startDate: '',
        endDate: '',
    });

    useEffect(() => {
        const fetchReservations = async () => {
            try {
                const response = await api.get('/reservations', {
                    params: {
                        page,
                        pageSize,
                        ...filters,
                    },
                });
                setReservations(response.data.reservations);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                console.error('Erreur lors de la récupération des réservations :', err);
                setAlert({ type: 'error', message: 'Erreur lors de la récupération des réservations.' });
            } finally {
                setLoading(false);
            }
        };

        fetchReservations();
    }, [page, pageSize, filters]);

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleSearch = () => {
        setPage(1); // Reset to first page when applying filters
        setLoading(true);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <div className="px-4 md:px-8">
            {/* <SearchField item={['Client', 'Id', 'Date']} /> */}
            <div className="max-w-lg">
                <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">Réservations</h3>
            </div>

            {/* Filter and Search Section */}
            <div className="flex flex-wrap items-center justify-between p-4 mt-4 mb-4 rounded-lg shadow-lg">
                <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Client / ID</label>
                        <input
                            type="text"
                            name="searchTerm"
                            value={filters.searchTerm}
                            onChange={handleFilterChange}
                            className="w-full p-2 border rounded-md"
                            placeholder="Rechercher"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date de début</label>
                        <input
                            type="date"
                            name="startDate"
                            value={filters.startDate}
                            onChange={handleFilterChange}
                            className="w-full p-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date de fin</label>
                        <input
                            type="date"
                            name="endDate"
                            value={filters.endDate}
                            onChange={handleFilterChange}
                            className="w-full p-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Statut</label>
                        <select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            className="w-full p-2 border rounded-md"
                        >
                            <option value="">Tous les statuts</option>
                            <option value="1">Confirmée</option>
                            <option value="2">Payée</option>
                            <option value="0">En attente</option>
                            <option value="-1">Rejetée</option>
                            <option value="-2">Annulée</option>
                        </select>
                    </div>
                </div>
                {/* <div className="flex justify-end w-full mt-4 md:mt-0">
                    <button
                        onClick={handleSearch}
                        className="px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg shadow hover:bg-indigo-500"
                    >
                        Rechercher
                    </button>
                </div> */}
            </div>

            {loading ? (
                <Loader />
            ) : (
                <div className="mt-6 overflow-x-auto border rounded-lg shadow-sm">
                    {alert.message && (
                        <AlertMessage type={alert.type} message={alert.message} onClose={() => setAlert({})} />
                    )}
                    <table className="w-full text-sm text-left table-auto">
                        <thead className="font-medium text-gray-600 border-b bg-gray-50">
                            <tr>
                                <th className="px-6 py-3">Id</th>
                                <th className="px-6 py-3">Clients</th>
                                <th className="px-6 py-3">Date</th>
                                <th className="px-6 py-3">Statut</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 divide-y">
                            {reservations.map((item, idx) => (
                                <tr
                                    key={idx}
                                    className="transition duration-300 cursor-pointer hover:bg-gray-200"
                                    onClick={() => navigate(`/reservation/details/${item.id}`)}
                                >
                                    <td className="px-6 py-4">{item.id}</td>
                                    <td className="px-6 py-4">{item.client.firstname} {item.client.lastname}</td>
                                    <td className="px-6 py-4">Du {new Date(item.startdate).toLocaleDateString()} au {new Date(item.endDate).toLocaleDateString()}</td>
                                    <td className="px-6 py-4">
                                        {item.status === 1 && (
                                            <span className="px-3 py-2 text-xs font-semibold text-green-600 rounded-full bg-green-50">
                                                Confirmée
                                            </span>
                                        )}
                                        {item.status === 2 && (
                                            <span className="px-3 py-2 text-xs font-semibold text-blue-600 rounded-full bg-blue-50">
                                                Payée
                                            </span>
                                        )}
                                        {item.status === 0 && (
                                            <span className="px-3 py-2 text-xs font-semibold text-yellow-600 rounded-full bg-yellow-50">
                                                En attente
                                            </span>
                                        )}
                                        {item.status === -1 && (
                                            <span className="px-3 py-2 text-xs font-semibold text-red-600 rounded-full bg-red-50">
                                                Rejetée
                                            </span>
                                        )}
                                        {item.status === -2 && (
                                            <span className="px-3 py-2 text-xs font-semibold text-red-600 rounded-full bg-red-50">
                                                Annulée
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            )}
        </div>
    );
}
