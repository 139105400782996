export default function ApercuReservation({ reservation }) {
    if (!reservation) {
        return <p className="text-center text-gray-500">Chargement des informations...</p>;
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString();
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return { text: "Confirmée", color: "text-green-800 bg-green-100" };
            case 2:
                return { text: "Payée", color: "text-blue-800 bg-blue-100" };
            case 0:
                return { text: "En attente", color: "text-yellow-800 bg-yellow-100" };
            case -1:
                return { text: "Rejetée", color: "text-red-800 bg-red-100" };
            case -2:
                return { text: "Annulée", color: "text-red-800 bg-red-100" };
            default:
                return { text: "Inconnu", color: "text-gray-800 bg-gray-100" };
        }
    };

    const statusLabel = getStatusLabel(reservation.status);

    return (
        <div className="flex flex-col items-start justify-start p-4">
            <p className="mt-3 text-xl font-bold text-gray-800 sm:text-2xl">Réservation</p>
            <p className="mt-2 text-gray-600">{reservation.id}</p>

            <h5 className="mt-3 text-xl font-bold text-gray-800 sm:text-2xl">Dates</h5>
            <p className="mt-2 text-gray-600">
                du {formatDate(reservation.startdate)} au {formatDate(reservation.endDate)}
            </p>

            <p className="text-xl font-semibold text-gray-800 sm:text-2xl">Statut</p>
            <p
                className={`pt-1 pb-1 pl-2 pr-2 mt-2 font-bold text-center rounded-xl ${statusLabel.color}`}
                style={{ width: 100, fontSize: 14 }}
            >
                {statusLabel.text}
            </p>
        </div>
    );
}
