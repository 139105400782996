import { useState } from "react"
import PaiementModal from "../Modal/Modal"
import Pagination from "../Pagination"

export default function Paiement(paiements) {

    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const tableItems = [
        {
            avatar: "https://images.unsplash.com/photo-1511485977113-f34c92461ad9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "Effectuée"
        },
        {
            avatar: "https://randomuser.me/api/portraits/men/86.jpg",
            id: "PAI-TYTF-HUHGF-POR",
            position: "10 Mars 2023",
            client: 'Jean Mabiala',
            status: "Manquée"
        },
        {
            avatar: "https://randomuser.me/api/portraits/women/79.jpg",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
        {
            avatar: "https://images.unsplash.com/photo-1439911767590-c724b615299d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
            id: "PAI-TYTF-HUHGF-POR",
            date: "10 Janv 2023",
            client: 'Jean Mabiala',
            status: "En attente"
        },
    ]


    return (
        <div className="px-4 py-10 md:px-8">
            <div className="max-w-lg">
                <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">
                    Paiements
                </h3>
                {/* <p className="mt-2 text-gray-600">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p> */}
            </div>
            <div className="mt-12 overflow-x-auto border rounded-lg shadow-sm">
                <table className="w-full text-sm text-left table-auto">
                    <thead className="font-medium text-gray-600 border-b bg-gray-50">
                        <tr>
                            <th className="px-6 py-3">Id</th>
                            <th className="px-6 py-3">Clients</th>
                            <th className="px-6 py-3">Date</th>
                            <th className="px-6 py-3">status</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                        {
                            paiements && paiements.rows && paiements.rows.length > 0 ? (
                                paiements.rows.map((item, idx) => (
                                    <tr key={idx} className="w-full text-left transition rounded cursor-pointer hover:bg-gray-200 duration-900" onClick={() => { setOpenModal(true); setSelectedItem(item) }}>
                                        <td className="flex items-center px-6 py-3 gap-x-3 whitespace-nowrap">
                                            <div>
                                                <span className="block text-sm font-medium text-gray-700">{item.id}</span>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.reservation.client.firstname} {item.reservation.client.firstname}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.createdAt}</td>
                                        {item.status === "En attente" && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-green-600 bg-green-50`}>{item.status}</span>}
                                        {item.status === "Effectuée" && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-blue-600 bg-blue-50`}>{item.status}</span>}
                                        {item.status === "Manquée" && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-red-600 bg-red-50`}>{item.status}</span>}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="px-6 py-4 text-center text-gray-500" colSpan="4">
                                        Aucun paiement trouvé.
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>

                </table>
            </div>
            {/* <Pagination/> */}
            {openModal && <PaiementModal open={openModal} setOpen={setOpenModal} item={selectedItem} />}

        </div>
    )
}