import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PreviewGalerie = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { images, currentIndex } = location.state;
    const [index, setIndex] = useState(currentIndex);

    const prevImage = () => {
        setIndex((index - 1 + images.length) % images.length);
    };

    const nextImage = () => {
        setIndex((index + 1) % images.length);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-900">
            <div className="relative w-full max-w-4xl">
                <button
                    className="absolute top-0 right-0 z-20 m-4 text-3xl text-white"
                    onClick={() => navigate(-1)}
                >
                    &times;
                </button>
                <button
                    className="absolute left-0 z-10 text-3xl text-white transform -translate-y-1/2 top-1/2"
                    onClick={prevImage}
                >
                    &#10094;
                </button>
                <img
                    src={images[index]}
                    alt="Preview"
                    className="object-contain w-full transition duration-300 transform rounded-lg h-96"
                />
                <button
                    className="absolute right-0 z-10 text-3xl text-white transform -translate-y-1/2 top-1/2"
                    onClick={nextImage}
                >
                    &#10095;
                </button>
            </div>
            <div className="flex max-w-4xl mt-4 overflow-x-auto">
                {images.map((url, i) => (
                    <img
                        key={i}
                        src={url}
                        alt={`Thumbnail ${i}`}
                        className={`w-24 h-24 m-2 cursor-pointer rounded ${i === index ? 'border-2 border-white' : ''}`}
                        onClick={() => setIndex(i)}
                    />
                ))}
            </div>
        </div>
    );
};

export default PreviewGalerie;
