import { useNavigate } from 'react-router-dom';

export default function Galerie({ galeries }) {
    const navigate = useNavigate();
    const images = galeries.map(galerie => `${process.env.REACT_APP_API_PICTURE_URL}${galerie.path.replace(/\\/g, '/')}`);
    
    // Function to split the images into groups of 4
    const splitImagesIntoGroups = (images, groupSize) => {
        let result = [];
        for (let i = 0; i < images.length; i += groupSize) {
            result.push(images.slice(i, i + groupSize));
        }
        return result;
    };

    const groupedImages = splitImagesIntoGroups(images, 4);

    const openInNewWindow = (url, index) => {
        navigate('/preview', { state: { images, currentIndex: index } });
    };

    return (
        <div className="p-4 md:p-8">
            {groupedImages.map((group, groupIndex) => (
                <div key={groupIndex} className="grid grid-cols-2 gap-4 mb-4 md:grid-cols-4">
                    {group.map((url, index) => (
                        <div key={index} className="relative overflow-hidden rounded-lg">
                            <img 
                                className="object-cover w-full h-48 transition duration-300 transform rounded-lg cursor-pointer hover:scale-105" 
                                src={url} 
                                alt={`Galerie image ${groupIndex * 4 + index + 1}`} 
                                onClick={() => openInNewWindow(url, groupIndex * 4 + index)}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
