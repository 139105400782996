import { useNavigate } from "react-router-dom"
export default function Integration(){
    const navigate = useNavigate();
    const data = [
        {
        'nom' : 'Ngassaki',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },
       {
        'nom' : 'Ngassaki',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },

       {
        'nom' : 'Ngassaki',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },

       {
        'nom' : 'SAMBA',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },

       {
        'nom' : 'MOUTOMBO',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },

       {
        'nom' : 'GOMA',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },

       {
        'nom' : 'MBOUMBA',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },

       {
        'nom' : 'MASSAMBA',
        'email' : 'ngassakisylvia@gmail.com',
        'date': '22/05/2024',
        'services': 'services',
        'APIkey':'XXX-XXX-XXX-XXXX6XXXXX',
        'date-exp':'03/03/2027',
        'date-sub':'03/03/2024',
       },


    
    ]
    return(
        <div className="rounded-lg overflow-hidden mx-4 md:mx-10 my-5">
        <table className="w-full table-fixed">
            <thead>
                <tr className="bg-gray-100">
                    <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Nom</th>
                    <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Email</th>
                    <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">APIkey</th>
                    <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Services</th>
                    <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">date-sub</th>
                    <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">date-exp</th>
                </tr>
            </thead>
            <tbody className="bg-white">
                {
                    data.map((item, idx)=>(
                    <tr 
                    onClick={()=>navigate( '/parametres/integration/detailintegration')}>
                        <td className="py-4 px-6 border-b border-gray-200"> {item.nom} </td>
                        <td className="py-4 px-6 border-b border-gray-200 truncate">{item.email} </td>
                        <td className="py-4 px-6 border-b border-gray-200">{item.APIkey} </td>
                        <td className="py-4 px-6 border-b border-gray-200">{item.services} </td>
                        <td className="py-4 px-6 border-b border-gray-200">{item["date-sub"]} </td>
                        <td className="py-4 px-6 border-b border-gray-200">{item["date-exp"]} </td>
                        
                    </tr>
                    ))
                }
                
               
            </tbody>
        </table>
    </div>
    )
}