import { useNavigate } from "react-router-dom"
import Pagination from "../Pagination"
import ReservationModal from "../Modal/ModalReservation";
import { useState } from "react";

export default function Reservation({ reservations }) {

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();


    return (
        <>
            <div className="px-4 py-10 md:px-8">
                <div className="max-w-lg">
                    <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">
                        Reservation
                    </h3>
                    {/* <p className="mt-2 text-gray-600">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p> */}
                </div>
                <div className="mt-12 overflow-x-auto border rounded-lg shadow-sm">

                    <table className="w-full text-sm text-left table-auto">
                        <thead className="font-medium text-gray-600 border-b bg-gray-50">
                            <tr>
                                <th className="px-6 py-3">Id</th>
                                <th className="px-6 py-3">Clients</th>
                                <th className="px-6 py-3">Date</th>
                                <th className="px-6 py-3">status</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 divide-y">
                            {
                                reservations && reservations.length > 0 ? (

                                    reservations.map((item, idx) => (

                                        <tr key={idx} className="w-full text-left transition rounded cursor-pointer hover:bg-gray-200 duration-900"
                                            onClick={() => { setSelectedItem(item); setOpenModal(true) }}
                                        >

                                            <td className="flex items-center px-6 py-3 gap-x-3 whitespace-nowrap">
                                                <div>
                                                    <span className="block text-sm font-medium text-gray-700">{item.id}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">{item.client.firstname} {item.client.lastname}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">Du {new Date(item.startdate).toLocaleDateString()} au {new Date(item.endDate).toLocaleDateString()}</td>
                                            {item.status === 1 && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-green-600 bg-green-50`}>
                                                Confirmée
                                            </span>}

                                            {item.status === 2 && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-blue-600 bg-blue-50`}>
                                                Payée
                                            </span>}

                                            {item.status === 0 && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-yellow-600 bg-yellow-50`}>
                                                En attente
                                            </span>}
                                            {item.status === -1 && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-red-600 bg-red-50`}>
                                                Rejetée
                                            </span>}
                                            {item.status  === -2 && <span className={`px-3 py-2 rounded-full font-semibold text-xs text-red-600 bg-red-50`}>
                                                Annulée
                                            </span>}
                                            </tr>

                                    ))
                                ) : (
                                    <tr>
                                        <td className="px-6 py-4 text-center text-gray-500" colSpan="4">
                                            Aucune réservation trouvé.
                                        </td>
                                    </tr>
                                )

                            }
                        </tbody>
                    </table>
                </div>

                {/* <Pagination/> */}
            </div>

            {openModal && selectedItem && <ReservationModal open={openModal} setOpen={setOpenModal} item={selectedItem} />}
        </>
    )
}