import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SearchField from "../../Components/Forms/SearchField";
import Pagination from "./../../Components/Pagination";
import api from '../../api/api';
import Loader from '../../Components/Loader';

export default function Proprietaires() {
    const [owners, setOwners] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getAllOwners = async () => {
            setLoading(true);
            try {
                const response = await api.get('/owners', {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: search
                    }
                });
                console.log('La réponse est :',response.data.owners)
                console.log('Le nombre de page :' ,response.data.totalPages)
                setOwners(response.data.owners);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                console.error('Erreur lors de la récupération des propriétaires:', err);
            }
            setLoading(false);
        }
        getAllOwners();
    }, [currentPage, search]);

    return (
        <div className="px-4 md:px-8">
            <SearchField onSearch={(term) => setSearch(term)} />
            <div className="items-start justify-between md:flex">
                <div className="max-w-lg">
                    <h3 className="text-xl font-bold text-gray-800 sm:text-2xl">Propriétaires</h3>
                    {/* <p className="mt-2 text-gray-600">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
                <div className="mt-3 md:mt-0">
                    {/* <button
                        className="inline-block px-4 py-2 font-medium text-white duration-150 bg-indigo-600 rounded-lg hover:bg-indigo-500 active:bg-indigo-700 md:text-sm"
                        onClick={() => { navigate('/proprietaire/creation') }}
                    >
                        Ajouter
                    </button> */}
                </div>
            </div>
            <div className="relative mt-12 overflow-auto h-max">
                {loading ? (
                    <Loader/>
                ) : owners.length === 0 ? (
                    <div className="flex items-center justify-center h-64 text-center text-gray-600">
                        <p className="text-xl font-medium">Aucun propriétaire trouvé.</p>
                    </div>
                ) : (
                    <table className="w-full my-5 overflow-x-scroll">
                        <thead className="font-medium text-gray-600 border-b">
                            <tr>
                                <th className="w-1/4 px-6 py-4 font-bold text-left text-gray-600 uppercase">Nom</th>
                                <th className="w-1/4 px-6 py-4 font-bold text-left text-gray-600 uppercase">Prenom</th>
                                <th className="w-1/4 px-6 py-4 font-bold text-left text-gray-600 uppercase">Email</th>
                                <th className="w-1/4 px-6 py-4 font-bold text-left text-gray-600 uppercase">Date</th>
                                <th className="w-1/4 px-6 py-4 font-bold text-left text-gray-600 uppercase">Telephone</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 divide-y">
                            {owners.map((owner) => (
                                <tr key={owner.id}>
                                    {console.log('Le propriétaire est :',owner)}
                                    <td className="px-6 py-4 border-b border-gray-200">
                                        <div className="flex items-center">
                                            <img alt={`Profil de ${owner.client.firstname}`} src={owner.client.picture ? `${process.env.REACT_APP_API_PICTURE_URL}${owner.client.picture}` : "https://randomuser.me/api/portraits/men/86.jpg"} className="w-10 h-10 mr-4 rounded-full" />
                                            <span>{owner.client.lastname}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 truncate border-b border-gray-200">{owner.client.firstname}</td>
                                    <td className="px-6 py-4 truncate border-b border-gray-200">{owner.client.email}</td>
                                    <td className="px-6 py-4 border-b border-gray-200">{new Date(owner.createdAt).toLocaleDateString()}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 ">{owner.client.phone}</td>
                                    <td className="text-right">
                                        <div onClick={() => navigate(`/proprietaires/details/${owner.id}`)} className="cursor-pointer py-1.5 px-3 text-gray-600 text-center hover:text-gray-500 duration-150 hover:bg-gray-50 border rounded-lg">
                                            Gérer
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {owners.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            )}
        </div>
    );
}
