import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ColorAnimated from "../../Components/Blob/colorAnimate/colorAnimate";

export default function Parametres() {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState('compte');

    return (
        <>
            <header className="relative">
                <div>
                    <ColorAnimated>
                        <h1 className="mx-5 text-3xl font-bold tracking-tight text-white">Paramètres</h1>
                    </ColorAnimated>
                </div>
            </header>

            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="py-6 lg:col-span-2">
                    <nav className="space-y-1">
                        <a 
                            href="#"
                            className={`border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium ${selectedItem === 'compte' && ' bg-purple-200 border-l-2 border-purple-900 hover:bg-purple-300'}`}
                            onClick={() => {
                                navigate('/parametres/compte');
                                setSelectedItem('compte');
                            }}
                        >
                            <svg className="flex-shrink-0 w-6 h-6 mr-3 -ml-1 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                            </svg>
                            <span className="truncate">Compte</span>
                        </a>

                        <a 
                            href="#"
                            className={`border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium ${selectedItem === 'facturation' && ' bg-purple-200 border-l-2 border-purple-900 hover:bg-purple-300'}`}
                            onClick={() => {
                                navigate('/parametres/facturation');
                                setSelectedItem('facturation');
                            }}
                        >
                            <svg className="flex-shrink-0 w-6 h-6 mr-3 -ml-1 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                            </svg>
                            <span className="truncate">Facturation</span>
                        </a>

                        {/* <a 
                            href="#"
                            className={`border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium ${selectedItem === 'integration' && ' bg-purple-200 border-l-2 border-purple-900 hover:bg-purple-300'}`}
                            onClick={() => {
                                navigate('/parametres/integration');
                                setSelectedItem('integration');
                            }}
                        >
                            <svg className="flex-shrink-0 w-6 h-6 mr-3 -ml-1 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                            <span className="truncate">Intégrations</span>
                        </a> */}

                        <a 
                            href="#"
                            className={`border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium ${selectedItem === 'push' && ' bg-purple-200 border-l-2 border-purple-900 hover:bg-purple-300'}`}
                            onClick={() => {
                                navigate('/parametres/push');
                                setSelectedItem('push');
                            }}
                        >
                            <svg className="flex-shrink-0 w-6 h-6 mr-3 -ml-1 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 17v2a3 3 0 006 0v-2M8.25 9V7.5a3.75 3.75 0 117.5 0V9a2.25 2.25 0 002.25 2.25v5.25M5.25 12.25V17.25h13.5V12.25m-10.5-3h-3a3 3 0 00-3 3v1.5M15.75 9h3a3 3 0 013 3v1.5m-7.5 6h-4.5" />
                            </svg>
                            <span className="truncate">Notifications Push</span>
                        </a>
                    </nav>
                </aside>
                <div className="w-full lg:col-span-10"> 
                    <Outlet/>    
                </div>
            </div>
        </>
    );
}
