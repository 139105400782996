import api from "../../api/api";
import { useParams } from "react-router-dom";
import Tab from "../../Components/Annonce/Tab";
import { useEffect, useRef, useState } from "react";
import Apercu from "./Apercu";
import Appartement from "../../Components/Sections/Appartements";
import PrOprietaire from "./../../Components/Sections/Proprietaire";
import Paiement from "../../Components/Sections/Paiement";
import Reservation from "../../Components/Sections/Reservation";
import Commentaire from "../../Components/Sections/Commentaire";
import Blob from "../../Components/Blob/Blob";

export default function DetailsAnnonce() {
    const { id } = useParams();
    const [annonceDetails, setAnnonceDetails] = useState(null);

    const [selectedTab, setSelectedTab] = useState("Apercu");
    const tabItems = [
        "Apercu",
        "Detail",
        "Proprietaire",
        "Reservations",
        "Commentaires",
    ];

    const apercuRef = useRef(null);
    const appartRef = useRef(null);
    const proprioRef = useRef(null);
    const reservationRef = useRef(null);
    const commentRef = useRef(null);

    useEffect(() => {
        const getAnnonceDetail = async () => {
            try {
                const response = await api.get(`/annonces/${id}`);
                console.log('resul :', response.data)
                setAnnonceDetails(response.data)
            } catch (err) {
                console.error('Erreur lors de la récupération des details de l\'annonce:', err)
            }
        }
        getAnnonceDetail();
    }, [id])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    switch (entry.target) {
                        case apercuRef.current:
                            setSelectedTab("Apercu");
                            break;
                        case appartRef.current:
                            setSelectedTab("Detail");
                            break;
                        case proprioRef.current:
                            setSelectedTab("Proprietaire");
                            break;
                        case reservationRef.current:
                            setSelectedTab("Reservations");
                            break;
                        case commentRef.current:
                            setSelectedTab("Commentaires");
                            break;
                        default:
                            break;
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (apercuRef.current) observer.observe(apercuRef.current);
        if (appartRef.current) observer.observe(appartRef.current);
        if (proprioRef.current) observer.observe(proprioRef.current);
        if (reservationRef.current) observer.observe(reservationRef.current);
        if (commentRef.current) observer.observe(commentRef.current);

        return () => {
            if (apercuRef.current) observer.unobserve(apercuRef.current);
            if (appartRef.current) observer.unobserve(appartRef.current);
            if (proprioRef.current) observer.unobserve(proprioRef.current);
            if (reservationRef.current) observer.unobserve(reservationRef.current);
            if (commentRef.current) observer.unobserve(commentRef.current);
        };
    }, []);


    const scrollToSection = (section) => {
        switch (section) {
            case "Apercu":
                apercuRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case "Detail":
                appartRef.current.scrollIntoView({ behavior: 'smooth' });

                break;
            case "Proprietaire":
                proprioRef.current.scrollIntoView({ behavior: 'smooth' });
                break;

            case "Reservations":
                reservationRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case "Commentaires":
                commentRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    };

    return (

        <div className="grid grid-cols-12 gap-4">

            <div className="h-full col-span-12 row-auto p-4 overflow-auto md: lg:fixed top-12">
                <Tab tabItems={tabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onTabClick={scrollToSection} />
            </div>
            <div className="col-span-12 md:col-span-10 md:col-start-3" style={{ borderLeftWidth: 2 }}>

                {
                    annonceDetails &&
                    <div ref={apercuRef}>
                        {
                            annonceDetails &&
                            <Apercu detail={annonceDetails.annonce} />
                        }
                    </div>
                }
                {
                    annonceDetails && 
                    <div ref={appartRef}>
                        {
                            annonceDetails &&
                            <Appartement detail={annonceDetails.annonce} />
                        }
                    </div>
                }
                {
                    annonceDetails && 
                    <div ref={proprioRef}>
                        {
                            annonceDetails &&
                            <PrOprietaire observed={selectedTab} detail={annonceDetails.annonce} />
                        }
                    </div>
                }
                {
                    annonceDetails &&
                    <div ref={reservationRef}>
                        {
                            annonceDetails &&
                            <Reservation reservations={annonceDetails.annonce.reservation} />
                        }
                    </div>
                }
                {
                    annonceDetails &&
                    <div ref={commentRef}>
                        {
                            <Commentaire commentaires={annonceDetails.annonce.commentaires} />
                        }
                    </div>
                }
            </div>

        </div>
    )
}