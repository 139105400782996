import React, { useState } from "react";
import Drawer from "../../Navigation/Drawer";
import AppBar from "../../Navigation/AppBar";
import Increase from "../../Components/Stats/Increase";
import Discrease from "../../Components/Stats/Discrease";
import Last from "../../Components/Reports/Last";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../../Components/Context";

export default function Dashboard(){

    const [openDrawer, setOpenDrawer] = useState(false);
    const {actifItem, setActifItem, dropdown, setDropDown} = useContext(MyContext);

    return (
            <div className="flex h-screen bg-gray-50" onClick={()=> dropdown && setDropDown(false)}>
                {openDrawer && 
                    <Drawer open={openDrawer} setOpen={setOpenDrawer} selectedItem={actifItem} setSelectedItem={setActifItem}/>}

                
                
                <div className="flex flex-col flex-1 overflow-y-auto">
                    <AppBar openDrawer={openDrawer} setDrawer={setOpenDrawer}/>
                        <div className="py-4">
                            <Outlet/>
                        </div>
                        {/*<p>hjvvb : {dropdown}</p>*/}
                </div>
                
            </div>
    )
}